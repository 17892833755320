import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, CircularProgress, Button } from "@mui/material";
import Template15 from "../../Pages/Templates/Template15/Template15";
import Template16 from "../../Pages/Templates/Template16/Template16";
import ColorPicker from "../../Components/ColorsHanding/ColorPicker";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLocalStorage";
import { useDispatch } from "react-redux"; // To dispatch actions
import { setTheme } from "../../Redux/Slices/FormSlice"; // Import the setTheme action
import { useMediaQuery } from "@mui/material"; // Responsive hook
import UploadPhoto from '../../Components/ImageHanding/UploadPhoto'
const Step1 = ({ handleSubmit, isLoading }) => {
  const theme = localStorage.getItem("theme");
  const [selectedTemplate, setSelectedTemplate] = useState(theme); // 1 for Template15, 2 for Template16
  const dispatch = useDispatch(); // Get dispatch from Redux
  const { t } = useTranslation();
  const tex = useLanguage();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
 
  
  useEffect(() => {
    if (theme === "1" || theme === "2") {
      setSelectedTemplate(parseInt(theme, 10)); // Convert string to number
      localStorage.setItem("theme", theme);
      dispatch(setTheme({ theme: selectedTemplate })); // Dispatch the selected template
    }
  }, []);

  useEffect(() => {
    setSelectedTemplate(parseInt(selectedTemplate, 10)); // Convert string to number
    dispatch(setTheme({ theme: selectedTemplate })); // Dispatch the selected template
    localStorage.setItem("theme", selectedTemplate);
  }, [selectedTemplate]);

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(parseInt(template, 10)); // Convert string to number
  };
  const handleFormSubmit = (event) => {
    event.preventDefault(); 
    handleSubmit();
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "2rem 2rem",
        backgroundColor: "#f4f5f7",
        transition: "background-color 0.3s ease-in-out",
      }}
    >
    <Box
    sx={{
      width: "100%",
      padding: isSmallScreen ? "1rem 0.5rem" : "2rem",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease-in-out",
      "&:hover": {
        boxShadow: "0 6px 14px rgba(0, 0, 0, 0.2)",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        color: "#353D4B",
        fontSize: { xs: "1.2rem", md: "1.5rem" },
        fontWeight: "bold",
        textAlign: tex?.language === "ar" ? "right" : "left",
        mb: 3,
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          width: "50px",
          height: "4px",
          backgroundColor: "#705ea8",
          bottom: "-10px",
          left: tex?.language === "ar" ? "auto" : 0,
          right: tex?.language === "ar" ? 0 : "auto",
        },
      }}
    >
      {t("Profile_Theme")}
    </Typography>

    {/* Template Selection Buttons */}
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Button
          onClick={() => handleTemplateSelect(1)}
          variant="outlined"
          sx={{
            border: selectedTemplate === 1 ? "2px solid #17488a" : "2px solid gray",
            borderRadius: "12px",
            color: selectedTemplate === 1 ? "#17488a" : "gray",
            fontWeight: "bold",
            padding: "12px",
            width: "100%",
            transition: "transform 0.2s ease",
            "&:hover": {
              transform: "scale(1.05)",
              borderColor: selectedTemplate === 1 ? "#17488a" : "gray",
            },
          }}
        >
           
            {t("Template_1")}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          onClick={() => handleTemplateSelect(2)}
          variant="outlined"
          sx={{
            border: selectedTemplate === 2 ? "2px solid #17488a" : "2px solid gray",
            borderRadius: "12px",
            color: selectedTemplate === 2 ? "#17488a" : "gray",
            fontWeight: "bold",
            padding: "12px",
            width: "100%",
            transition: "transform 0.2s ease",
            "&:hover": {
              transform: "scale(1.05)",
              borderColor: selectedTemplate === 2 ? "#17488a" : "gray",
            },
          }}
        >
             {t("Template_2")}
        </Button>
      </Grid>
    </Grid>
  </Box>

  {/* Color Picker Section */}


<Box
    sx={{
      marginTop: "2rem",
      padding: isSmallScreen ? "1rem 0.5rem" : "2rem",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease-in-out",
      "&:hover": {
        boxShadow: "0 6px 14px rgba(0, 0, 0, 0.2)",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        color: "#353D4B",
        fontSize: { xs: "1.2rem", md: "1.5rem" },
        fontWeight: "bold",
        textAlign: tex?.language === "ar" ? "right" : "left",
        mb: 3,
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          width: "50px",
          height: "4px",
          backgroundColor: "#705ea8",
          bottom: "-10px",
          left: tex?.language === "ar" ? "auto" : 0,
          right: tex?.language === "ar" ? 0 : "auto",
        },
      }}
    >
      {t("Profile_Image")}
    </Typography>
    <UploadPhoto type="profile" />
    
  </Box>



  <Box
    sx={{
      marginTop: "2rem",
      padding: isSmallScreen ? "1rem 0.5rem" : "2rem",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease-in-out",
      "&:hover": {
        boxShadow: "0 6px 14px rgba(0, 0, 0, 0.2)",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        color: "#353D4B",
        fontSize: { xs: "1.2rem", md: "1.5rem" },
        fontWeight: "bold",
        textAlign: tex?.language === "ar" ? "right" : "left",
        mb: 3,
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          width: "50px",
          height: "4px",
          backgroundColor: "#705ea8",
          bottom: "-10px",
          left: tex?.language === "ar" ? "auto" : 0,
          right: tex?.language === "ar" ? 0 : "auto",
        },
      }}
    >
      {t("Choose_Your_Template_Colors")}
    </Typography>
    <ColorPicker  />
    
  </Box>

      {/* Save Button Section */}
      <Grid item xs={12} textAlign="center " onClick={handleFormSubmit}>
          <Button type="submit" variant="contained" disabled={isLoading} sx={{ py: 1.5, px: 5, borderRadius: '30px' }}>
            {isLoading ? <CircularProgress size={24} /> : t("Save_Changes")}
          </Button>
        </Grid>
    </Box>
  );
};

export default Step1;