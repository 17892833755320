import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import TextField from '@mui/material/TextField';
import 'react-phone-input-2/lib/material.css';
import 'flag-icons/css/flag-icons.min.css';
import { Box, InputAdornment, FormHelperText } from '@mui/material';
import ar from 'react-phone-input-2/lang/ar.json'

const iso_3166_1 = ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];


const CustomPhoneInput = ({ value, onChange, label ,istextblack}) => {
  const [lang, setLang] = useState('en');
  const [userInput, setUserInput] = useState('');  // Store user input separately
  const [prefix, setPrefix] = useState('966'); // Default Saudi Arabia prefix
  const [error, setError] = useState('');  // For showing error messages

  // When value is passed, split it into prefix and user input
  useEffect(() => {
    if (value) {
      const extractedUserInput = value.slice(-9);  // Last 9 digits as userInput
      const extractedPrefix = value.slice(0, -9);  // The remaining part as prefix
      setUserInput(extractedUserInput);
      setPrefix(extractedPrefix);
    }
  }, [value]);

  // Set language from localStorage on component mount
  useEffect(() => {
    const storedLang = localStorage.getItem('lang') || 'en';
    setLang(storedLang);
  }, []);
useEffect(()=>{
console.log(userInput,'userInput')
},[userInput])
  const localization = localStorage.getItem('lang') === 'ar' ? ar : '';

  const handlePrefixPhoneChange = (phone, countryData) => {
    const newPrefix = countryData?.dialCode || '966';  // Get the prefix from the countryData
    const userEnteredValue = phone.slice(newPrefix.length).trim(); // Extract user input after prefix

    setPrefix(newPrefix);  // Update the prefix based on the country selection
    setUserInput(userEnteredValue);  // Update user input only

    // If user input is exactly 9 digits, pass the full phone number to the parent
    if (userEnteredValue.length === 9) {
      onChange(`${newPrefix}${userEnteredValue}`);
      setError('');
    } else {
      onChange(''); // Pass empty if invalid
    }
  };

  const handleUserInputChange = (e) => {
    const newUserInput = e.target.value;

    // Allow only up to 9 digits and validate the input
    if (newUserInput.length <= 9) {
      setUserInput(newUserInput);

      if (newUserInput.startsWith('0')) {
        setError('Number should not start with a 0');
        onChange('');  // Pass empty if invalid
      } else if (!/^\d*$/.test(newUserInput)) {
        setError('Only numbers are allowed'); 
        onChange('');  // Pass empty if invalid
      } else if (newUserInput.length !== 9  ) {
        if(newUserInput.length >0){
          setError('invalid phone number');

        }else{
          setError(''); 
 
        }
      } else {
        setError('');
        onChange(`${prefix}${newUserInput}`);  // Pass the valid phone number
      }
    }
  };

  const applyFlagStyles = () => {
    let styleElement = document.getElementById('custom-flag-styles');
    
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = 'custom-flag-styles';
      document.head.appendChild(styleElement);
    }
  
    let flagStyles = `
      .react-tel-input .flag {
        background-image: none;
        background-color: transparent;
        width: 16px; 
        height: 11px;
      }
      .react-tel-input .country-list {
        top: -27px;
      }
      .react-tel-input {
        width: 42px;
        margin-top: 22px;
      }
      .react-tel-input .country-list .country-name {
        padding-left: 5px;
        padding-right: 5px;
      }
      .react-tel-input .flag-dropdown {
        border: none;
        background-color: transparent;
      }
      .react-tel-input .flag-dropdown .selected-flag {
        background: transparent;
        border: none;
      }
      .react-tel-input .country-list .search {
        padding: 10px 0 0px 10px;
        border-bottom: 1px black solid;
      }
      .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
        background-color: transparent;
      }
      .react-tel-input .arrow {
        display: none;
      }
      .react-tel-input .form-control {
        display: none;
      }
      ${iso_3166_1.map(iso2 => `
        .react-tel-input .${iso2.toLowerCase()}::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 11px;
          background-image: url('https://cdn.jsdelivr.net/npm/flag-icons/flags/4x3/${iso2.toLowerCase()}.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transform: scale(2.7);
        }
      `).join('')}
    `;
    styleElement.innerHTML = flagStyles;
  };

  useEffect(() => {
    applyFlagStyles();
  }, []);

  return (
    <div className="custom-phone-input-container" style={{ display: 'flex', justifyContent: "start" }}>
      <div style={{ width: "52px", marginTop: "15px" }}>
        <PhoneInput
          country={'sa'}  
          localization={localization}
          value={`${prefix}${userInput}`}  // Pass the concatenated value
          onChange={handlePrefixPhoneChange}
          disableCountryCode={true}  // Country code handled separately
          enableSearch={true}
          searchPlaceholder={lang === 'en' ? 'Search country' : 'ابحث عن بلد'}
          specialLabel=""
          inputStyle={{
            width: '100%',
            height: '30px',
            direction: 'ltr',
            textAlign: 'left',
            borderBottom: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            paddingLeft: '50px',
          }}
          buttonStyle={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
          dropdownStyle={{
            borderRadius: '8px',
            padding: '0 10px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.6)',
            textAlign: lang === 'en' ? 'left' : 'right',
            color:'#000'
          }}
          searchStyle={{
            border: 'none',
            outline: 'none',
            padding: '2px 12px',
            fontSize: '18px',
            width: '100%',
            marginBottom: '10px',
          }}
          searchClass="w-full border-none"
          containerClass="w-full"
          inputClass="w-full border-none"
          buttonClass="flag-dropdown"
          dropdownClass="searchable-dropdown"
          countryCodeEditable={false}
          disableSearchIcon={true}
        />
      </div>
      <Box component="form" style={{ marginLeft: '10px', width: '100%' }}       sx={{ direction: 'ltr' }}
      >
      <TextField
      id="standard-basic"
      variant="standard"
      label={label}
      value={userInput}
      onChange={handleUserInputChange}  // Only update user input and propagate full phone number
      fullWidth
      error={!!error}  // Mark as error if there's an error
      InputProps={{
        startAdornment: userInput.length > 0 ? (  // Show prefix only when user input exists
          <InputAdornment position="start">
            <span style={{ color:  istextblack==true? '#5a5a5a': '#D3D3D3', fontSize: '18px', marginRight: '4px' }}>
              {prefix} - {/* Show the prefix */}
            </span>
          </InputAdornment>
        ) : null,
        inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },  // Only allow numbers
        style: {
          fontSize: '18px',
          color: istextblack==true? '#000': '#fff',  // Input text should always be black
        },
      }}
      sx={{
        '& label': {
          transition: 'margin 0.5s ease', // Smooth transition
        },
       
        '& .css-cj8mzk-MuiFormLabel-root-MuiInputLabel-root':{
          marginLeft: (localStorage.getItem('lang') == 'en') ? '-55px' : 'auto',
          marginRight: (localStorage.getItem('lang') == 'ar') ? '-49px' : 'auto',


        }
      }}
    />
        {/* Show error message below */}
        {error && (
          <FormHelperText error>{error}</FormHelperText>
        )}
      </Box>
    </div>
  );
};

export default CustomPhoneInput;