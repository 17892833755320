import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  return (
    <Box
      p={"2rem"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh",
        fontFamily: "Robote_bold",
      }}
    >
      <Stack
        direction={"row"} // Change to row
        justifyContent={"center"}
        gap={"1rem"}
      >
        <Button
          component={Link}
          to={"/ResetEmail"}
          variant="contained"
          startIcon={<EmailIcon />} // Add Email icon
          sx={{
            color: "#fff",
            backgroundColor: "#e5cd79",
            "&:hover": {
              backgroundColor: "#d2b965",
            },
            boxShadow: "rgb(38, 38, 38) 0px 1px 5px 1px",
            fontFamily: "Robote_bold",
          }}
        >
          {t("Change_Email")}
        </Button>
        <Button
          component={Link}
          to={"/Reset"}
          variant="contained"
          startIcon={<LockIcon />} // Add Lock icon
          sx={{
            color: "#fff",
            backgroundColor: "#e5cd79",
            "&:hover": {
              backgroundColor: "#d2b965",
            },
            boxShadow: "rgb(38, 38, 38) 0px 1px 5px 1px",
            fontFamily: "Robote_bold",
            fontWeight: "600",
          }}
        >
          Change Password
        </Button>
      </Stack>
    </Box>
  );
};

export default Settings;
