import React, { useRef, useState } from "react";
import { Box, Button, Stack, Modal } from "@mui/material";
import { Cropper, CircleStencil,RectangleStencil } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

import { t } from "i18next";

const style = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "rgba(255, 255, 255, 0.1)", // Glass effect
  backdropFilter: "blur(12px)", // Frosted glass
  zIndex: 10000,
  padding: { xs: "2rem", md: "4rem" },
  animation: "fadeIn 0.5s ease", // Smooth fade-in animation
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.7)", // Subtle shadow
  border: "1px solid rgba(255, 255, 255, 0.8)", // Light border
};

export default function ProfileCropper({ src, getCroppedFile, onCancel }) {
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
let theme=localStorage.getItem('theme')
  const handleSave = () => {
    if (croppedImage && croppedImage.getCanvas) {
      const canvas = croppedImage.getCanvas();
      canvas.toBlob((blob) => {
        const file = new File([blob], "cropped.jpg", { type: "image/jpeg" });
        getCroppedFile(file);
      }, "image/jpeg");
    }
  };

  const handleCancel = () => {
    onCancel(); // Handle cancel
  };

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* Profile Image Cropper */}
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            borderRadius: "10px",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.7)", // Modern shadow effect
            padding: "1rem",
            backdropFilter: "blur(30px)",
            width: "100%",
            maxWidth: "450px", // Larger for better usability
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Vertically center the cropper
            height: "80vh", // Full height for vertical centering
          }}
        >
        <Cropper
        className="example__cropper"
        backgroundClassName="example__cropper-background"

        ref={cropperRef}
        src={src}
        stencilComponent={theme==1?CircleStencil:theme==2?RectangleStencil:CircleStencil} // Use the circular stencil for profile images
        stencilProps={{
          aspectRatio:`${ theme == 1? '1':theme==2? '6/8':'1'}`, // Portrait aspect ratio
          overlayClassName: "dark-overlay", // Add custom class for dark overlay
        }}
        onChange={(cropperState) => setCroppedImage(cropperState)}
        style={{
          width: "100%", // Ensure the cropper takes full width of the container
          height: "auto", // Maintain the original aspect ratio of the image
          maxHeight: "70vh", // Ensure it fits within the viewport
          backgroundColor:"#000"
        }}
        viewMode={2}
        background={true}
        adjustStencil={true}
        moveImage={true}
        responsive={true}
        autoCropArea={0.7} // Set the auto crop area to maximum
        guides={true}
        dragMode="move"
        restore={true} // Enable restoring the image to its original aspect
        zoomable={true} // Allow zooming in and out of the image
      /> 
      </Box>

        {/* Responsive buttons with fixed position */}
        <Stack
          direction={{ xs: "column", sm: "row" }} // Stacks vertically on small screens, horizontally on larger
          spacing={3}
          mt={2} // Added some margin top for proper positioning
          sx={{
            position: { xs: "static", md: "absolute" }, // Fixed absolute on larger screens, static on small
            bottom: { md: "2rem" }, // Positioned at the bottom of the modal on larger screens
            width: "100%",
            justifyContent: { xs: "center", md: "center" }, // Center on small screens, right on larger
          }}
        >
          <Button
            sx={{
              width: { xs: "100%", sm: "auto" }, // Full width on small, auto on larger
              padding: "0.8rem 2rem",
              fontSize: "1rem",
              fontWeight: 600,
              backgroundColor: "#4CAF50",
              color: "#fff",
              borderRadius: "8px", // Rounded buttons for casual feel
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#45A049",
              },
            }}
            variant="contained"
            onClick={handleSave}
          >
            {t("Confirm")}
          </Button>
          <Button
            sx={{
              width: { xs: "100%", sm: "auto" }, // Full width on small, auto on larger
              padding: "0.8rem 2rem",
              fontSize: "1rem",
              fontWeight: 600,
              backgroundColor: "#F44336",
              color: "#fff",
              borderRadius: "8px",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#E53935",
              },
            }}
            variant="contained"
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}