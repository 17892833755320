import ResetEmail from "../Components/ResetEmail";
import Reset from "../Components/ResetPassword";
import SendVeruficationToEmail from "../Components/sendVerificationCodeToEmail";
import ResendOTP from "../Components/resendOTP";
import Dashboard from "../Pages/Dashboard";
import Settings from "../Pages/Settings";
import Steper from "../Pages/Steper";
import Template2 from "../Pages/Templates/Template2/Template2.js";
import PrivateRoutes from "./PrivateRoutes";
import Forgetpassword from "../Components/Forgetpassword";
import SendOtpForForgetPasword from "../Components/sendVerificationCodeToEmail";
import Posts from '../Pages/Posts.js'
import Home from "../Pages/Home.js";

const AuthenticationRoutes = {
  element: <PrivateRoutes />,

  children: [
    {
      element: <Steper />,
      path: "Steper",
    },

   
   
    { element: <Settings />, path: "/settings" },
    { element: <Posts />, path: "/posts" },

    { element: <Home />, path: "/home" },
   
    {
      element: <Dashboard />,
      path: "/Dashboard",
    },
   

  
  ],
};
export default AuthenticationRoutes;
