import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Typography, TextField, Autocomplete, Grid, Chip, MobileStepper, CircularProgress,createTheme, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import UploadPhoto from '../ImageHanding/UploadPhoto';
import medicalCategories from '../../Forms/Steps/medicalCategories.json';
import CustomPhoneInput from '../CustomPhoneInput/CustomPhoneInput';
import { setCustomContacts, setLocation, setBasicInfo, setIsPersonal ,removeCustomContacts} from '../../Redux/Slices/FormSlice';
import WelcomeModal from './WelcomeModal';

const ModalStep = ({isLoading , open, handleClose, handleNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useSelector(state => state.form);
  const [localStepIndex, setLocalStepIndex] = useState(0);
  const [userType, setUserType] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedMedicalSpecialties, setSelectedMedicalSpecialties] = useState([]);
  const [countries, setCountries] = useState([]);
  // const lang = localStorage.getItem("lang");
  const [cityError, setCityError] = useState(false);
  const [lang, setlang] = useState('en');
  const categories = medicalCategories[lang];
  const fontFamily = lang === 'ar' ? 'system-ui' : 'Robote_bold';
  const languageKey = lang === 'en' ? 'name_en' : 'name_ar';

  useEffect(()=>{
setlang(localStorage.getItem("lang"))
  },[localStorage.getItem("lang")])
  const firstSectionCustomTheme = createTheme({
    
    
    palette: {
   mode: "dark",
   primary: {
     main: "#17488a",
   }, 
   secondary: {
     main: "#705ea8",
   },
   lighter: {
     main: "#497cbe",
   },
 },
 typography: {
   fontFamily: `${fontFamily}`, // Use dynamic fontFamily

   title: {
    
     fontSize: "0.9rem",
     display: "block",
   },
   linkSelected: {
     backgroundColor: "#fff",
     fontSize: "0.9rem",
     display: "block",
   },
 },

 components: {
   MuiTextField: {
     defaultProps: {
     
       InputLabelProps: {
         style: {
           textAlign: lang === 'ar' ? 'right' : 'left',  
           right: lang === 'ar' ? 0 : 'auto',            
           left: lang === 'ar' ? 'auto' : 0,            
           direction: lang === 'ar' ? 'rtl' : 'ltr',     
           fontSize: '1rem',                            
           color: '#fff',   
                                    
         },
       },
    
       InputProps: {
         inputProps: {
           style: {
             textAlign: lang === 'ar' ? 'right' : 'left',
             direction: lang === 'ar' ? 'rtl' : 'ltr',    
             color: '#fff',             
           },
         },
       },
     },
   },
 },
});

  const totalSteps = userType === 'individual' ? 6 : 6;

  useEffect(() => {
    axios.get("https://tebsocial.api.cubex-dev.com/api/countries")
      .then(response => setCountries(response.data))
      .catch(error => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (form.basic.location) {
      const [previousCountry, previousCity] = form.basic.location.split(', ');
      const countryMatch = countries.find(country => country[languageKey] === previousCountry);
      if (countryMatch) {
        setSelectedCountry(countryMatch);
        fetchCities(countryMatch, previousCity);
      }
    }
  }, [form.basic.location, countries, languageKey]);

  const fetchCities = (country, selectedCityName = "") => {
    axios.get(`https://tebsocial.api.cubex-dev.com/api/countries/${country.id}/cities`)
      .then(response => {
        setFilteredCities(response.data);
        const cityMatch = response.data.find(city => city[languageKey] === selectedCityName);
        if (cityMatch) {
          setSelectedCity(cityMatch);
        } else {
          setSelectedCity(null);
        }
      })
      .catch(error => console.error("Error fetching cities:", error));
  };

  const handleCountryChange = (event, newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedCity(null);
    if (newCountry) {
      fetchCities(newCountry);
    }
  };

  const handleCityChange = (event, newCity) => {
    if (!selectedCountry) {
      setCityError(true);
    } else {
      setCityError(false);
      setSelectedCity(newCity);
      if (newCity) {
        const locationValue = `${selectedCountry[languageKey]}, ${newCity[languageKey]}`;
        handleInputChange('location', locationValue);
        dispatch(setLocation({ location: locationValue }));
      }
    }
  };

  const handleInputChange = (name, value) => {
    dispatch(setBasicInfo({ basic: { ...form.basic, [name]: value } }));
  };

  // const handleSpecialtyChange = (event, newValue) => {
  //   if (userType === 'individual') {
  //     setSelectedMedicalSpecialties([newValue]);
  //     dispatch(setCustomContacts({ id: 0, link: newValue, name_link: newValue }));
  //   } else {
  //     setSelectedMedicalSpecialties(newValue);
  //     newValue.forEach((specialty, index) => {
  //       dispatch(setCustomContacts({ id: index, link: specialty, name_link: specialty }));
  //     });
  //   }
  // };

  const isNextDisabled = () => {
    if (localStepIndex === 1) {
      return userType === 'individual'
        ? !(form.basic.firstName && form.basic.lastName)
        : !(form.basic.businessName);
    } else if (localStepIndex === 2) {
      return !(form.basic.email && form.basic.phoneNumber);
    }
    return false;
  };

  const getButtonText = () => {
    if (localStepIndex === 3) {
      if (!selectedCity || !selectedCountry) {
        return t("Skip");
      } else {
        return t("Next");
      }
    } else if (localStepIndex === 4) {
      if (userType === 'individual') {
        if (!selectedMedicalSpecialties.length && form.basic.businessName === '') {
          return t("Skip");
        } else if (!selectedMedicalSpecialties.length || form.basic.businessName === '') {
          return t("Skip");
        } else {
          return t("Next");
        }
      } else {
        if (!selectedMedicalSpecialties.length) {
          return t("Skip");
        } else {
          return t("Next");
        }
      }
    } else if (localStepIndex == 5) {
      
      if ( form.basic.profileImage == '') {
        return t("finish");
      }  else {
        return t("finish");
      }
    } else {
      return t("Next");
    }
  };

  const customMessages = [
    t("Are_you_using_Teb_as_an_individual_or_a_company"),
    t("Please_enter_your_Info"),
    t("Enter_your_contact_details"),
    t("Select_your_country_and_city"),
    t("Select_your_medical_specialties"),
    t("Upload_your_profile_images"),
  ];

  const renderStepContent = () => {
    switch (localStepIndex) {
      case 0:
        return <SelectUserType onSelect={setUserType} onNext={() => setLocalStepIndex(1)} />;
      case 1:
        return <UserDetailsForm userType={userType} form={form} onInputChange={handleInputChange} />;
      case 2:
        return <ContactInfoForm form={form} onInputChange={handleInputChange} />;
      case 3:
        return <LocationAndSpecialtiesForm countries={countries} t={t} selectedCountry={selectedCountry} handleCountryChange={handleCountryChange} filteredCities={filteredCities} selectedCity={selectedCity} handleCityChange={handleCityChange} cityError={cityError} userType={userType} selectedMedicalSpecialties={selectedMedicalSpecialties} categories={categories} />;
      case 4:
        return <BusinessInfo userType={userType} form={form} onInputChange={handleInputChange} selectedMedicalSpecialties={selectedMedicalSpecialties} setSelectedMedicalSpecialties={setSelectedMedicalSpecialties} />;
      case 5:
        return <UploadImages />;
      default:
        return <Typography>{t("Unknown Step")}</Typography>;
    }

  };
 
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(true);
  useEffect(()=>{
    console.log(welcomeModalOpen,'welcomeModalOpen')
      },[welcomeModalOpen])

  const handleCloseWelcome = () => {
  setWelcomeModalOpen(false)
}
 
  return ( 
    <ThemeProvider theme={firstSectionCustomTheme}>

    <>
    {welcomeModalOpen ==true ?  <>
    <WelcomeModal openWelcom={welcomeModalOpen} handleCloseWelcome={handleCloseWelcome} />
    </>
  :<>
    <Modal
      open={open}
      onClose={handleClose}
      disableBackdropClick
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box 
        sx={{
        
      
          textAlign: lang === 'ar' ? 'right' : 'left', 
        
          direction: lang === 'ar' ? 'rtl' : 'ltr', 
         
         
          p: 4,
          maxWidth: 400,
          bgcolor: '#000',
          borderRadius: '20px',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
       
          animation: 'fadeInUp 0.8s ease-in-out',
          background: `linear-gradient(135deg, #17488a, #705ea8, #497cbe)`,
          color: '#fff',
        
        }}
      >
        <Typography sx={{ mb: 2,  fontSize: '1.3rem', color: '#fff' }}>
          {customMessages[localStepIndex]}
        </Typography>
        {renderStepContent()}
        <NavigationControls isLoading={isLoading} localStepIndex={localStepIndex} totalSteps={totalSteps} isNextDisabled={isNextDisabled()} buttonText={getButtonText()} onNext={setLocalStepIndex} onFinish={handleNext} />
      </Box>
    </Modal>
     </>
  }
    </>
</ThemeProvider>
  );
};


// Step 1: User Type Selection
const SelectUserType = ({ onSelect, onNext }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  return (
    <Box 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'row', 
      gap: 2, 
      p: 4,
    
    
      width: '320px',
      margin: 'auto',
      transition: '0.3s ease-in-out', // Smooth transitions for hover
    }}
  >
    <Button 
      onClick={() => { onSelect('individual'); onNext(); }} 
     
       variant="contained"
          sx={{
            py: 1.5,
            px: 4,
            borderRadius: '25px',
            zIndex:"60",
            backgroundColor: '#fff',
            color: '#17488a',
            fontWeight: 'bold',
            textTransform: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              transform: 'scale(1.05)',
            },
          }}
    >
      {t("individual")}
    </Button>
  
    <Button 
      onClick={() => { onSelect('company'); onNext(); }} 
 variant="contained"
          sx={{
            py: 1.5,
            px: 4,
            borderRadius: '25px',
            zIndex:"60",
            backgroundColor: '#fff',
            color: '#17488a',
            fontWeight: 'bold',
            textTransform: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              transform: 'scale(1.05)',
            },
          }}
    >
      {t("Company")}
    </Button>
  </Box>
  );
};

// Step 2: User or Business Details Form
const UserDetailsForm = ({ userType, form, onInputChange }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch(); // Ensure you import useDispatch from react-redux

  // Update Redux state when userType changes
  useEffect(() => {
    // Set 'true' if 'individual', otherwise 'false'
    dispatch(setIsPersonal({ isPersonal: userType === 'individual' ? 'true' : 'false' }));
  }, [userType, dispatch]);

  return (

    <Grid   container spacing={2} sx={{ direction: lang === 'ar' ? 'rtl' : 'ltr', textAlign: 'right',  width: '320px', }}>
      {userType === 'individual' ? (
        <>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label={t("First_Name")}
              value={form.basic.firstName}
              onChange={(e) => onInputChange('firstName', e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
                label={t("Last_Name")}
              value={form.basic.lastName}
              onChange={(e) => onInputChange('lastName', e.target.value)}
              variant="standard"
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("clinic_name")}
            value={form.basic.businessName}
            onChange={(e) => onInputChange('businessName', e.target.value)}
            variant="standard"
          />
        </Grid>
      )}
    </Grid>
  );
};
// Step 3: Contact Information Form (Email and Phone Number)
const ContactInfoForm = ({ form, onInputChange }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  return (
    <Grid   container spacing={2} sx={{ direction: lang === 'ar' ? 'rtl' : 'ltr' , width: '320px',}}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("Email")}
          value={form.basic.email}
          onChange={(e) => onInputChange('email', e.target.value)}
          variant="standard"
          error={!/^[\w-.]+@[\w-]+\.[a-z]{2,4}$/i.test(form.basic.email) && form.basic.email !== ''}
          helperText={!/^[\w-.]+@[\w-]+\.[a-z]{2,4}$/i.test(form.basic.email) && form.basic.email !== '' ? t("Invalid email address") : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomPhoneInput
        istextblack={false}
          label={t("PhoneـNumber")}
          value={form.basic.phoneNumber}
          onChange={(value) => onInputChange('phoneNumber', value)}
        />
      </Grid>
    </Grid>
  );
};

// Step 4: Location and Medical Specialties Form
const LocationAndSpecialtiesForm = ({ countries, selectedCountry, handleCountryChange, filteredCities, selectedCity, handleCityChange, cityError, t }) => {
const lang = localStorage.getItem("lang");
const languageKey = lang === 'en' ? 'name_en' : 'name_ar';

return (
<Grid sx={{ width: '320px'}} container spacing={2}>
<Grid item xs={12} sm={6}>
<Autocomplete
options={countries}
getOptionLabel={(option) => option[languageKey]}
value={selectedCountry}
onChange={handleCountryChange}
renderInput={(params) => (
<TextField {...params} label={t("Country")} variant="standard" error={cityError} helperText={cityError ? t("Please select a country first.") : ""} />
)}
/>
</Grid>
<Grid item xs={12} sm={6}>
<Autocomplete
options={filteredCities}
disabled={selectedCountry==null}

getOptionLabel={(option) => option[languageKey]}
value={selectedCity}
onChange={handleCityChange}
renderInput={(params) => (
<TextField {...params} label={t("City")} variant="standard" focused={selectedCountry} sx={{
'.css-g8yrnh-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':{
color: '#fff'}
}}/>
)}
/>
</Grid>

</Grid>
);
};

// Step 5: Business Information and Medical Specialties (for individual and company)
const BusinessInfo = ({ userType, form, selectedMedicalSpecialties, setSelectedMedicalSpecialties }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const categories = medicalCategories[lang];
  const dispatch = useDispatch();

  // Function to handle medical specialties change
  const handleSpecialtyChange = (event, newValue) => {
    if (userType === 'individual') {
      // For individual, allow only a single specialty
      const specialty = newValue ? newValue[0] : null;
      setSelectedMedicalSpecialties(specialty ? [specialty] : []);
      if (specialty) {
        dispatch(setCustomContacts({ id: 0, link: specialty, name_link: specialty }));
      } else {
        dispatch(removeCustomContacts(0));  // Assuming 'removeCustomContacts' takes an ID and removes the contact
      }
    } else {
      // For company, allow multiple specialties
      setSelectedMedicalSpecialties(newValue);
      // Dispatch all new specialties to the store
      newValue.forEach((specialty, index) => {
        dispatch(setCustomContacts({ id: index, link: specialty, name_link: specialty }));
      });
      // Remove deleted specialties from Redux store
      // Find which specialties are removed by comparing current state
      const removedSpecialties = selectedMedicalSpecialties.filter(spec => !newValue.includes(spec));
      removedSpecialties.forEach(spec => {
        const indexToRemove = selectedMedicalSpecialties.findIndex(s => s === spec);
        dispatch(removeCustomContacts(indexToRemove));
      });
    }
  };

  // Handle business name change
  const handleBusinessNameChange = (e) => {
    const { value } = e.target;
    dispatch(setBasicInfo({ basic: { ...form.basic, businessName: value } }));
  };

  return (
    <Grid container spacing={2} sx={{ direction: lang === 'ar' ? 'rtl' : 'ltr',width: '320px' }}>
      {userType === 'individual' && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("clinic_name")}
            value={form.basic.businessName || ''}
            onChange={handleBusinessNameChange} // Use correct method to update business name
            variant="standard"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <MedicalSpecialtiesAutocomplete
          userType={userType}
          selectedMedicalSpecialties={selectedMedicalSpecialties}
          handleSpecialtyChange={handleSpecialtyChange}
          categories={categories}
          t={t}
        />
      </Grid>
    </Grid>
  );
};



// Step 6: Image Upload
const UploadImages = () => {
const { t } = useTranslation();
const lang = localStorage.getItem("lang");

return (
<Grid container spacing={2} sx={{ direction: lang === 'ar' ? 'rtl' : 'ltr' ,  width: '320px'}}>

<Grid item xs={12}>

<UploadPhoto type="profile" />
</Grid>
</Grid>
);
};

// Navigation Controls with dynamic button label
const NavigationControls = ({ localStepIndex, totalSteps, isNextDisabled, buttonText, onNext, onFinish ,isLoading}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  return (
    <Box sx={{ mt: 3, dir: lang === 'ar' ? 'ltr' : 'ltr' }}>
    {localStepIndex > 0 && (
      <MobileStepper
        variant="progress"
        steps={totalSteps}
        position="static"
        activeStep={localStepIndex}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          bgcolor: 'transparent',
          '.MuiMobileStepper-dotActive': { backgroundColor: '#705ea8' },
        }}
        nextButton={
          <Button
            onClick={() => (localStepIndex < totalSteps - 1 ? onNext(localStepIndex + 1) : onFinish())}
            disabled={isNextDisabled || isLoading}
            sx={{
              borderRadius: '20px',
              color: '#fff',
              bgcolor: '#17488a',
              '&:hover': {
                bgcolor: '#497cbe',
              },
              textTransform: 'none',
              px: 3,
              py: 1,
              fontSize: '0.875rem',
            }}
          >
            {isLoading ? <CircularProgress style={{marginX:"10px"}} size={24} sx={{ color: '#fff' }} /> : buttonText}
          </Button>
        }
        backButton={
          <Button
            onClick={() => onNext(localStepIndex - 1)}
            sx={{
              borderRadius: '20px',
              color: '#fff',
              bgcolor: '#705ea8',
              '&:hover': {
                bgcolor: '#497cbe',
              },
              textTransform: 'none',
              px: 3,
              py: 1,
              fontSize: '0.875rem',
            }}
          >
            {t("Back")}
          </Button>
        }
      />
    )}
  </Box>  );
};



const MedicalSpecialtiesAutocomplete = ({ userType, selectedMedicalSpecialties = [], handleSpecialtyChange, categories = {}, t }) => {
  const lang = localStorage.getItem("lang");

  const options = Object.entries(categories).flatMap(([category, specialties]) =>
    Array.isArray(specialties) ? specialties.map(specialty => `${category} — ${specialty}`) : []
  );

  return (
  <Autocomplete
  multiple={userType === 'company'}
  options={options}
  groupBy={(option) => option.split(' — ')[0]} // Group options by category
  getOptionLabel={(option) => option.split(' — ')[1]} // Get specialty label
  value={userType === 'company' ? selectedMedicalSpecialties : (selectedMedicalSpecialties[0] || null)}
  onChange={(event, newValue) => handleSpecialtyChange(event, userType === 'company' ? newValue : [newValue])}
  renderInput={(params) => (
    <TextField 
      {...params} // Spread all required params for proper integration with Autocomplete
      variant="standard"  
      label={t("Select_Specialties")} 
      InputLabelProps={{
        sx: {
          color: '#fff',
          fontSize: '1rem',
          "&.Mui-focused": {
            color: '#fff', // Color when focused
            textAlign: lang === 'ar' ? 'right' : 'left', // Align label text
            right: lang === 'ar' ? 0 : 'auto', // Position label for RTL or LTR
            left: lang === 'ar' ? 'auto' : 0, // Adjust positioning for RTL or LTR
            direction: lang === 'ar' ? 'rtl' : 'ltr', // Change direction based on language
          },
        },
      }}
    
    />
  )}
  renderTags={(tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        label={option}
        sx={{
          '.MuiChip-deleteIcon': {
            margin: '0', // Remove the margin
          },
        }}
      />
    ))
  }
/>
  );
};



export default ModalStep;
