import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

 const WelcomeModal = ({ openWelcom, handleCloseWelcome }) => {
  const lang = localStorage.getItem("lang");

  return (
    <Modal
      open={openWelcom}
      onClose={handleCloseWelcome}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          p: 4,
          maxWidth: 400,
          bgcolor: '#fff',
          borderRadius: '20px',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
          textAlign: 'center',
          animation: 'fadeInUp 0.8s ease-in-out',
          background: `linear-gradient(135deg, #17488a, #705ea8, #497cbe)`,
          color: '#fff',
          overflow: 'hidden',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#fff' }}>
     {lang=='en'?" Welcome Doctor !":"! مرحبا دكتور "}    
        </Typography>
        <Typography sx={{ mb: 4, fontSize: '1rem', color: '#eee' }}>
       
          {lang=='en'?"   We're excited to have you on board. Let's get started!":"  ! نحن متحمسون لكونك معنا، هيا نبدأ"}
       
        </Typography>
        <Button
         
          onClick={handleCloseWelcome}
           variant="contained"
          sx={{
            py: 1.5,
            px: 4,
            borderRadius: '25px',
            zIndex:"60",
            backgroundColor: '#fff',
            color: '#17488a',
            fontWeight: 'bold',
            textTransform: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              transform: 'scale(1.05)',
            },
          }}
        >
         
          {lang=='en'?" Continue":"   متابعة"}
       
        </Button>
        <Box
          sx={{
            position: 'absolute',
            top: '-20%',
            left: '-20%',
            width: '400px',
            height: '400px',
            bgcolor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '50%',
            animation: 'spin 6s linear infinite',
          }}
        />
      </Box>
    </Modal>
  );
};

// Keyframes for the animation
const styles = `
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`;

// Add the styles to the document head
document.head.insertAdjacentHTML('beforeend', `<style>${styles}</style>`);


export default WelcomeModal;
