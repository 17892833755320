import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setContacts, removeContacts, setContactsOrder } from "../Redux/Slices/FormSlice";
import { Box, Grid, IconButton, TextField, Typography, Button, useMediaQuery, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../hook/useLocalStorage";
import { FaTrash, FaCheck } from "react-icons/fa";
import { Reorder } from "framer-motion";
import { isURL } from "validator"; // Use validator.js to validate URLs

const DraggableList = ({ buttonNames, type }) => {
  const form = useSelector((state) => state.form);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tex } = useLanguage();
  const isArabic = tex?.language === "ar";
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [inputFields, setInputFields] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [collapsedFields, setCollapsedFields] = useState({});
  const [validInputs, setValidInputs] = useState({});

  useEffect(() => {
    console.log(buttonNames,'buttonNames')
    const fields = (form.contacts ?? []).map((contact) => ({
      idInthback: contact.idintheback,
      id: contact.type,
      name: contact.type,
      logo: contact?.logo || "",
      placeholder: buttonNames.find((button) => button.name === contact.type)?.placeholder || "",
      value: contact.value,
      isValid: isURL(contact.value)
    }));
    setInputFields(fields);
    setCollapsedFields(fields.reduce((acc, field) => ({ ...acc, [field.id]: true }), {}));
  }, [form.contacts, buttonNames]);

  useEffect(() => {
    setInputValues(
      form.contacts.reduce((values, contact) => {
        values[contact.type] = contact.value;
        return values;
      }, {})
    );
  }, [form.contacts]);

  const handleButtonClick = (buttonName) => {
    console.log(buttonName,'buttonName')
    const newInput = {
      idInthback: buttonName.id,
      id: buttonName.name,
      name: buttonName.name,
      logo: buttonName.logo,
      placeholder: buttonName.placeholder,
      value: "",
      isValid: false
    };
    setInputFields([newInput, ...inputFields]);
    setCollapsedFields({ ...collapsedFields, [buttonName.name]: false });
  };

  const handleInputChange = (id, value) => {
    setInputValues({ ...inputValues, [id]: value });
    setValidInputs({ ...validInputs, [id]: isURL(value) });
  };

  const handleConfirm = (id) => {
    const field = inputFields.find(field => field.id === id);
    if (validInputs[id]) {
      dispatch(setContacts({
        idInthback: field.idInthback,
        type: id,
        value: inputValues[id],
        logo: field.logo  // Ensure logo is included in dispatch
      }));
      setCollapsedFields({ ...collapsedFields, [id]: true });
    }
  };

  const handleInputClose = (id) => {
    setInputFields(inputFields.filter(field => field.id !== id));
    setInputValues(prev => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
    dispatch(removeContacts({ type: id }));
  };

  const handleCollapse = (id) => {
    const currentCollapseState = !collapsedFields[id];
    setCollapsedFields({ ...collapsedFields, [id]: currentCollapseState });
    if (!currentCollapseState) { // If we are expanding
      setValidInputs({ ...validInputs, [id]: isURL(inputValues[id] || "") });
    }
  };

  const renderCollapsedGrid = () => (
    <Grid container spacing={2}>
      {inputFields
        .filter((inputField) => collapsedFields[inputField.id])
        .map((inputField) => (
          <Grid item xs={4} key={inputField.id}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => handleCollapse(inputField.id)}
            >
              <img
                src={inputField.logo}
                alt="Collapsed"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </Box>
          </Grid>
        ))}
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", mx: "auto", mt: 4 }}>
      {buttonNames.filter(button => !inputFields.some(input => input.name === button.name)).length > 0 && (
        <Typography variant="h6" align="center" sx={{ mb: 2, fontWeight: "bold" }}>
          {t("Add_your_social_media_links")}
        </Typography>
      )}

      <Grid container spacing={2} justifyContent="center">
        {buttonNames.filter(button => !inputFields.some(input => input.name === button.name)).map((button, index) => (
          <Grid item xs={4} sm={3} md={2} key={index}>
            <Button onClick={() => handleButtonClick(button)} sx={{
              display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
              position:"relative",
              padding: "1rem", border: "2px solid #D1D5DB", borderRadius: "10px",
              backgroundColor: "#fff", "&:hover": { backgroundColor: "#E5E7EB", transform: "scale(1.05)" },
            }}>
              <img src={button.logo} alt={button.name} style={{ width: "30px", height: "30px" }} />
              <Typography variant="p" sx={{ mt: 1, textAlign: "center", fontWeight: "normal",fontSize:"13px" }}>
                {button.name}
              </Typography>
              <div style={{position:"absolute",top:"-6px",right:"5px",fontSize:"20px"}}>+</div>
            </Button>
          </Grid>
        ))}
        
      </Grid>

      {inputFields.length > 0 && (
        <div className='text-red-400'>
      {/**   <Typography variant="h6" align="center" sx={{ mb: 2, fontWeight: "bold", mt: 3 }} >
          {t("Manage_Your_Social_Media_Links")}
        </Typography>
        */}
        </div>
      )}

      {inputFields.length > 0 && (
        <Box sx={{
          mt: 3, background: "#f0f4f8", borderRadius: "12px", padding: "0.7rem",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", display: "flex", flexDirection: "column", alignItems: "center"
        }}>
          <Reorder.Group axis="y" values={inputFields} onReorder={setInputFields}>
            {inputFields.map(inputField => (
              <Reorder.Item key={inputField.id} value={inputField}>
                <Collapse in={!collapsedFields[inputField.id]} timeout={300}>
                  <Box sx={{
                    display: "flex", flexDirection: isArabic ? "row-reverse" : "row", justifyContent: "space-between",
                    alignItems: "center", backgroundColor: "#fff", padding: "1rem", borderRadius: "12px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", mb: 2, width: isSmallScreen ? "100%" : "100%",
                    "&:hover": { transform: "scale(1.02)" },
                  }}>
                    <img src={inputField.logo} alt="User" style={{ width: "50px", height: "50px", margin: "0 1rem" }} />
                    <TextField fullWidth size="small" placeholder={inputField.placeholder} value={inputValues[inputField.id] || ""}
                      onChange={e => handleInputChange(inputField.id, e.target.value)}
                      sx={{ mx: 2, "& .MuiInputBase-input": { backgroundColor: "#f9fafb", padding: "0.5rem", borderRadius: "8px" } }} />
                    <IconButton onClick={() => handleInputClose(inputField.id)} sx={{ color: "#ef4444", padding: "6px" }}>
                      <FaTrash size={16} />
                    </IconButton>
                    <IconButton onClick={() => handleConfirm(inputField.id)} sx={{ color: validInputs[inputField.id] ? "#10b981" : "#9ca3af", padding: "6px" }} disabled={!validInputs[inputField.id]}>
                      <FaCheck size={16} />
                    </IconButton>
                  </Box>
                </Collapse>
              </Reorder.Item>
            ))}
          </Reorder.Group>

          {/* Render collapsed items grid */}
          {renderCollapsedGrid()}
        </Box>
      )}
    </Box>
  );
};

export default DraggableList;