import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  Collapse,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import { HiMenuAlt3 } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { FiBarChart2 } from "react-icons/fi";
import { BsFillInfoCircleFill, BsFillPeopleFill } from "react-icons/bs";
import { FaPalette } from "react-icons/fa";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Logo from "../../assets/images/logo-white.png";
import PreViewMain from "./Preview/PreViewMain";

function Navigation({ languagee, handleLanguageToggle, handleLogout }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [expanded, setExpanded] = useState({});

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleExpandClick = (itemName) => {
    setExpanded((prev) => ({ ...prev, [itemName]: !prev[itemName] }));
  };

  // Helper function to check if the active section matches location.state
  const isActiveSection = (section) => {
    return location.pathname === "/steper" && location.state?.activeSection === section;
  };

  const pages = [
    {
      name: "profile_manage",
      label: languagee === "en" ? "Profile" : " الملف الشخصي",
      icon: <AiOutlineUser />,
      subItems: [
        {
          label: languagee === "en" ? "Basic Information" : "المعلومات الأساسية",
          icon: <BsFillInfoCircleFill />,
          onClick: () => {
            navigate("/steper", { state: { activeSection: 0 } });
            closeSidebar();
          },
          isActive: isActiveSection(0),
        },
        {
          label: languagee === "en" ? "Social Links" : "الروابط الاجتماعية",
          icon: <BsFillPeopleFill />,
          onClick: () => {
            navigate("/steper", { state: { activeSection: 1 } });
            closeSidebar();
          },
          isActive: isActiveSection(1),
        },
        {
          label: languagee === "en" ? "Theme & Colors" : "السمات والألوان",
          icon: <FaPalette />,
          onClick: () => {
            navigate("/steper", { state: { activeSection: 2 } });
            closeSidebar();
          },
          isActive: isActiveSection(2),
        },
      ],
    },
    {
      name: "posts",
      label: languagee === "en" ? "Posts" : "المنشورات",
      icon: <MdOutlineDashboard />,
      path: "/posts",
      isActive: location.pathname === "/posts",
    },
    {
      name: "analytics",
      label: languagee === "en" ? "Analytics" : "الإحصائيات",
      icon: <FiBarChart2 />,
      path: "/dashboard",
      isActive: location.pathname === "/dashboard",
    },
  ];

  return (
    <>
      {location.pathname !== "/Forgetpassword" && location.pathname !== "/" && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "80px",
            background: "#17488a",
            padding: "0 1rem",
            color: "#fff",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1200,
            flexDirection: languagee === "ar" ? "row" : "row",
          }}
        >
          <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} color="inherit">
            {sidebarOpen ? <CloseIcon size={26} /> : <HiMenuAlt3 size={30} />}
          </IconButton>
          <Box sx={{ textAlign: "center", width: "120px" }}>
            <img src={Logo} alt="Logo" style={{ width: "100px",height:"auto" }} />
          </Box>
          <Box>
          <PreViewMain language={languagee}/>
          </Box>
      
        </Box>
      )}

      {sidebarOpen && (
        <>
          <Box
            onClick={closeSidebar}
            sx={{
              position: "fixed",
              top: 0,
              left: languagee === "ar" ? "auto" : 0,
              right: languagee === "ar" ? 0 : "auto",
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1300,
            }}
          />
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: languagee === "ar" ? "auto" : 0,
              right: languagee === "ar" ? 0 : "auto",
              height: "100vh",
              width: "240px",
              backgroundColor: "#17488a",
              color: "#fff",
              zIndex: 1400,
              overflowY: "auto",
              boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)",
              display: "flex",
              flexDirection: "column",
              textAlign: languagee === "ar" ? "right" : "left",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingX: "20px", flexDirection: languagee === "ar" ? "row-reverse" : "row" }}>
              <Box sx={{ textAlign: "center", width: "100px" }}>
                <img src={Logo} alt="Logo" style={{ width: "100px" }} />
              </Box>
              <IconButton onClick={closeSidebar} sx={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#705ea8" }} />

            <Stack spacing={1} sx={{ mt: 2, padding: "10px 20px" }}>
              {pages.map((page) => (
                <Box key={page.name}>
                  {page.subItems ? (
                    <>
                      <Box
                        onClick={() => handleExpandClick(page.name)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: 2,
                          padding: "8px",
                          borderRadius: "8px",
                          transition: "background-color 0.3s ease",
                          "&:hover": { backgroundColor: "#705ea8" },
                          backgroundColor: page.isActive ? "#497cbe" : "transparent",
                          flexDirection: languagee === "ar" ? "row-reverse" : "row",
                        }}
                      >
                        <Box sx={{ fontSize: 20 }}>{page.icon}</Box>
                        <Typography variant="p" sx={{ color: "#fff",whiteSpace:"nowrap" }}>
                          {page.label}
                        </Typography>
                        <IconButton
                          size="small"
                          sx={{
                            color: "#fff",
                            transform: expanded[page.name] ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.3s",
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </Box>
                      <Collapse in={expanded[page.name]} timeout="auto" unmountOnExit>
                        <Stack spacing={1} sx={{ pl: languagee === "ar" ? 0 : 2, pr: languagee === "ar" ? 2 : 0, color: "#ddd" }}>
                          {page.subItems.map((subItem, index) => (
                            <Box
                              key={subItem.label}
                              onClick={subItem.onClick}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                paddingY: "8px",
                                cursor: "pointer",
                                borderRadius: "8px",
                                backgroundColor: subItem.isActive ? "#705ea8" : "transparent",
                                "&:hover": { backgroundColor: "#705ea8" },
                                flexDirection: languagee === "ar" ? "row-reverse" : "row",
                              }}
                            >
                              <Box sx={{ fontSize: 18 }}>{subItem.icon}</Box>
                              <Typography variant="body2" sx={{  color: "#fff" }}>
                                {subItem.label}
                              </Typography>
                            </Box>
                          ))}
                        </Stack>
                      </Collapse>
                    </>
                  ) : (
                    <Box
                      onClick={() => {
                        navigate(page.path);
                        closeSidebar();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "8px",
                        backgroundColor: page.isActive ? "#705ea8" : "transparent",
                        "&:hover": { backgroundColor: "#705ea8" },
                        transition: "background-color 0.3s ease",
                        flexDirection: languagee === "ar" ? "row-reverse" : "row",
                      }}
                    >
                      <Box sx={{ fontSize: 20 }}>{page.icon}</Box>
                      <Typography variant="p" sx={{ color: "#fff" }}>
                        {page.label}
                      </Typography>
                    </Box>
                  )}
                  <Divider sx={{ backgroundColor: "#497cbe", my: 1 }} />
                </Box>
              ))}
            </Stack>

            <Box sx={{ mt:5,pt: 2, px: 2 }}>
              <Button
                fullWidth
                onClick={handleLanguageToggle}
                startIcon={languagee === "ar" ? null : <LanguageIcon />}
                endIcon={languagee === "ar" ? <LanguageIcon /> : null}
                sx={{
                  justifyContent: languagee === "ar" ? "flex-end" : "flex-start",
                  color: "#fff",
                  borderRadius: "8px",
                  padding: "8px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#705ea8" },
                  gap: 1,
                }}
              >
                {languagee === "en" ? "العربية" : "English"}
              </Button>
              <Divider sx={{ backgroundColor: "#705ea8", my: 1 }} />
              <Button
                fullWidth
                onClick={()=>{closeSidebar(); handleLogout()}}
                startIcon={languagee === "ar" ? null : <LogoutIcon />}
                endIcon={languagee === "ar" ? <LogoutIcon /> : null}
                sx={{
                  justifyContent: languagee === "ar" ? "flex-end" : "flex-start",
                  color: "#fff",
                  borderRadius: "8px",
                  padding: "8px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "red" },
                  gap: 1,
                }}
              >
                {languagee === "en" ? "Logout" : "تسجيل الخروج"}
              </Button>
              <Divider sx={{ backgroundColor: "#705ea8", my: 1 }} />

              <Typography variant="body2" color="gray" textAlign="center" sx={{ mt: 2 }}>
               
                {languagee === "en" ? " © 2024 Teb Social" : " © 2024 طب سوشل"}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default Navigation;