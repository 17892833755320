import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Card,
  useTheme,
  useMediaQuery
} from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useFetchStatsQuery } from "../Redux/API/statistics";
import { Bar } from 'react-chartjs-2';
// import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const StatisticsPage = () => {
  const { data, isLoading } = useFetchStatsQuery(1);
  const [dataa, setDataa] = useState([]);
  const token = localStorage.getItem('token');
  const profile = localStorage.getItem('profile');
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://tebsocial.api.cubex-dev.com/api/profile/${profile}`, config);
        console.log(response.data.data,'response.data.data')
        setDataa(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [profile, token]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const gradient = document.createElement('canvas').getContext('2d').createLinearGradient(0, 0, 0, 400);
  gradient.addColorStop(0, theme.palette.primary.light);
  gradient.addColorStop(1, theme.palette.primary.dark);

  const chartData = {
    labels: dataa?.primary_links?.map(item => item.name), // Assuming 'name' is the correct key
    datasets: [{
      label: 'Views',
      data: dataa?.primary_links?.map(item => item.views), // Assuming 'views' is the correct key
      backgroundColor: 'rgba(75,192,192,0.2)', // or use gradient as before
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 1
    }],
    // icons: dataa?.map(item =>`${item.logo}`) // Assuming 'icon' is the correct key for icon URLs
  };
  // dataa?.map(item =>console.l `${item.logo}`)
  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        ticks: {
          color: theme.palette.text.primary
        }
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {
          color: theme.palette.text.primary
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '2rem', backgroundColor: '#f7f7f7',marginTop:"10px" }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card elevation={3} sx={{ padding: '2rem' }}>
            <Typography variant={matches ? "h3" : "h4"} sx={{ textAlign: 'center', color: theme.palette.primary.main, fontWeight: 'bold' }}>
              {t('totalـcardـTabs')}
            </Typography>
            <Typography variant={matches ? "h2" : "h3"} sx={{ mt: 2, textAlign: 'center', fontWeight: 'bold', color: theme.palette.secondary.main }}>
              {dataa?.views || 0}
            </Typography>
            <Typography variant="h6" sx={{ mt: 4, textAlign: 'center', fontWeight: 'bold' }}>
              {t('LinksTabs')}
            </Typography>
            <Bar data={chartData} options={chartOptions} />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticsPage;