import { Box, Typography, Stack, Tooltip, Grid, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";
import DraggableList from "../../Components/DraggableList";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLocalStorage";
import { useMediaQuery } from "@mui/material"; // Import useMediaQuery for responsive design

const Step3 = ({ handleSubmit, isLoading }) => {
  const [showMyLinksPopup, setShowMyLinksPopup] = useState(false);
  const [primaryLinksFromApi, setPrimaryLinksFromApi] = useState([]);
  const { otherLinks } = useSelector((state) => state.form);
  const primaryLinksApi = "https://tebsocial.api.cubex-dev.com/api/P_link";
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isArabic = language === "ar";

  const fetchData = async () => {
    try {
      const response = await axios.get(primaryLinksApi, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          ContentType: "application/json",
        },
      });
      setPrimaryLinksFromApi(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const profile = localStorage.getItem("profile");
  const isLargeScreen = useMediaQuery('(min-width:600px)');

  return (
    <Box
      className="flex mt-10 overflow-x-hidden w-screen"
      sx={{
        justifyContent: isArabic ? "center" : "center",
        paddingX: isLargeScreen ? 2 : 2,
        width: "100%",
      }}
    >
      <Box className={`w-[100%] md:w-[100%] ${isArabic ? "-mr-0 md:mr-10" : "ml-0"}`}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Stack spacing={2}>
              {otherLinks.length > 0 && (
                <Tooltip title={t("Show my Links")}>
                  <LinkIcon
                    sx={{
                      color: "#fff",
                      fontSize: "2.4rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowMyLinksPopup(!showMyLinksPopup);
                    }}
                  />
                </Tooltip>
              )}

              <Typography
                variant="h1"
                sx={{
              
                  fontSize: "1.2rem", // Base font size for small screens
                  textAlign: isArabic ? "right" : "left",
                  fontWeight: "bold",
                  marginTop: "10px",
               
                  "@media (min-width: 600px)": {
                    fontSize: "1.5rem",
                  },
                }}
              >
                {t("Manage_Your_Social_Media_Links")}
              </Typography>

              <Box
                sx={{
                  margin: "0.8rem 0",
                  padding: isLargeScreen ? "0.5rem" : "0.5rem",
                  borderRadius: "8px",
                  backgroundColor: "#f0f4f8",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                <DraggableList
                  type="primary"
                  buttonNames={primaryLinksFromApi}
                />
              </Box>

              <Grid item xs={12} textAlign="center" onClick={()=>{handleSubmit()}}>
          <Button type="submit" variant="contained" disabled={isLoading} sx={{ py: 1.5, px: 5, borderRadius: '30px' }}>
            {isLoading ? <CircularProgress size={24} /> : t("Save_Changes")}
          </Button>
        </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Step3;