import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, Radio, RadioGroup, TextField, Typography, FormControlLabel } from '@mui/material';
import { FaRegTrashAlt } from "react-icons/fa";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DraggableFeatured from '../Components/DraggableFeatured';

function Posts() {
  const [postContent, setPostContent] = useState("");
  const [mediaType, setMediaType] = useState("image");
  const [mediaFile, setMediaFile] = useState(null);
  const [posts, setPosts] = useState([]);

  // Handle content change
  const handleContentChange = (event) => {
    setPostContent(event.target.value);
  };

  // Handle media type change (image or video)
  const handleMediaTypeChange = (event) => {
    setMediaType(event.target.value);
  };

  // Handle media file upload
  const handleMediaUpload = (event) => {
    setMediaFile(event.target.files[0]);
  };

  // Handle publish post
  const handlePublishPost = () => {
    if (postContent || mediaFile) {
      setPosts([...posts, { content: postContent, media: mediaFile, mediaType }]);
      setPostContent("");
      setMediaFile(null);
    }
  };

  // Handle delete post
  const handleDeletePost = (index) => {
    const updatedPosts = posts.filter((_, postIndex) => postIndex !== index);
    setPosts(updatedPosts);
  };

  return (
    <Box padding={4} maxWidth="800px" margin="0 auto">
      {/* Add New Post Section */}
    <DraggableFeatured/>
    </Box>
  );
}

export default Posts;