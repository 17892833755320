// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        choose_your_theme:"Choose Your colors",
        change_your_template:'Change Your Template',
        Information: " Information",
        Media: "Medias",
        // Colors: "Colors",
        PersonalـData: "Personal Account Information:",
        Company_Data:"Company Data",
        First_Name: "First Name",
        Last_Name: "Last Name",
        PhoneـNumber: "Phone Number",
        WhatsAppـPhoneـNumber:"WhatsApp Number",
        Email: "Email",
        JobـTitle: "Job Title",
        BusinessـName: "Company Name",
        Location: "Location",
        Bio: "About Me",
        Profile_Image: "Profile image",
        Cover_Image: "Add Cover Photo",
        Closeـpreview: "Add Close preview",
        Add_Links: "Social Media",
        Linkss: "Choose links",
        Add_Extra_Link: "Add other Link",
        Sections: "Media",
        Video_section: "Add Video",
        Pdf_section: "Add Pdf ",
        search: "Search",
        Main_background: "Main background",
        Button_background: "Button background",
        Back: "Back",
        Data_saved:"Data saved successfully!",
        Submit: "Submit",

        Next: "Next",
        Link_Tabs: "Link Tabs",
        ChangeـEmail: "Change Email",
        ChangeـPassword: "Change Password",
        Links: " Social Media",
        custom_input: "Custom Input",
        Colors: "Them & colos",
        Welcome_to_X_tag: "Welcome to Teb Socail",
        totalـcardـTabs: "total card Tabs :",
        Link_Tabs: "Link Tabs",
        New_Email: "New Email",
        Confirm_Email: "Confirm Email",
        RESET_EMAIL: "RESET EMAIL",
        dashboard: "Dashboard",
        Email_Verification: "Email Verification",
        We_have_sent_a_code_to_your_email: "We have sent a code to your email",
        Verify_Account: "Verify Account",
        Back: "Back",
        Resend_OTP: "Resend OTP",
        Home: "Home",
        Logout: "Logout",
        Preview: "Preview",
        phoneNumSecondary: "Secondary phone number",
        save: "Save",
        title: "title",
        Title:"Title",
        start_editing: "Start Editing",
        reenter_password: "Re enter password",
        New_password: "New password",
        Email: "Email Address",
        RESET_Password: "RESET Password",
        Swipe_Up_To_Preview: "Swipe Up To Preview",
        Swipe_Down_To_Preview: "Swipe Down To Close",
        alert: "Add the phone number without zero at the first",
        crop: "crop",
        Media: "Medias",
        successMessage: "Thank you for using Teb Socail",
        infosaved: "Info saved successfully",
        returntologin: "Back to step one",
Personal:'Profile Info',
Company:' facility',
BioForCompany:" About Company",
JobـTitle_company:"Company work",
Country:"Country",
City:"City",
Are_you_using_Teb_as_an_individual_or_a_company:"Account type  ",
individual:"Doctor",
Now_lets:"Add your social media links to your profile for easy sharing.",
Add_your_Media:"Media",
Now_lets_add:"share videos and PDF files by adding them to your profile.",
Choose_Your_Template_Colors:"Choose Your Template Colors",
finish:"finish",
Select_a_template:'Select a template for your profile',
jobTitle:'job Title',
ReservationLink:"Reservation Link",
BusinessName:"Company Name",
totalclickes:'Total Clickes',
LinksTabs:'social media visits',
Rotate:'Rotate',
FlipـV:'Flip V',
Add_your_social_media_links:"Add Your Social Media Account Links",
FlipـH:"Flip H",
cancel:"cancel",
Upload_Profile_Photo:'Upload Profile Photo',
UploadـCoverـPhoto:"Upload Cover Photo",
clinic_name:"Medical Facility Name  ",
Select_Specialties:"Select Specialties",
Save_Changes:"Save Changes",
Manage_Your_Social_Media_Links:"Manage Your Social Media Links",
Profile_Theme:"Profile Theme",
Template_1:"Template 1",
delete:"Delete",
Template_2:"Template 2",
Our_suggestions:"Our suggestions",
more_Options:"more Options",
New_Post:"New Post",
Editing_Post:"Editing Post",
Are_you_sure_deleteyou:"Delete this post? This action cannot be undone.",
Confirm_Deletion:"Confirm Deletion",
Confirm:'Confirm',
choose_Media:"choose Media",
Change_Media:"Change Media",
Please_enter_your_Info:"provide Basic Information",
Enter_your_contact_details:"Basic contact Information",
Select_your_country_and_city:"Select your Location",
Select_your_medical_specialties:" Medical specialties selection" ,
Skip:"Skip",
Upload_your_profile_images:"Upload your profile images",
Select_Custom_Colors:"Choose a color that suits you",
ButtonـColor:"Buttons color" ,
Background_Color:"Background Color",
  },
    },
    ar: {
      translation: {
        UploadـCoverـPhoto:"ارفاق صورة الخلفية",
        Upload_Profile_Photo:'ارفاق الصورة الشخصية',
        cancel:"إلغاء",
        FlipـH:'عكس',
        Data_saved:"تم حفظ بياناتك بنجاح",
        FlipـV:'قلب',
        Rotate:'تدوير',
        delete:"حذف",
        choose_Media:"اختر صورة/فيديو لمنشورك",
        Change_Media:"تغيير صورة منشورك",
        totalclickes:'عدد الضغطات الكلي',
        LinksTabs:'زيارات التوصال الاجتماعي',
        ReservationLink:"رابط الحجز",
        BusinessName:"اسم الشركة",
        finish:'إنهاء',
        jobTitle:'المسمى الوظيفي',
        Select_a_template:"اختر تصميم مناسب لملفك التعريفي",
        change_your_template:'غير هويتك ',
        choose_your_theme:"إختر الالوان التي تليق بك",
        Information: "المعلومات",
        Company_Data:":معلومات حساب الأعمال",
        Media: "التواصل الاجتماعي",
        Medias: "التواصل الاجتماعي",
        Colors: "الألوان",
        PersonalـData: ": معلومات الحساب الشخصي",
        First_Name: "الاسم الاول",
        Last_Name: "الاسم الاخير",
        PhoneـNumber: "رقم الاتصال",
        WhatsAppـPhoneـNumber:"رقم الواتساب",
        crop: "قص",
        Email: "الايميل",
        JobـTitle: "اسم المركز الوظيفي",
        BusinessـName: "اسم الشركة",
        Location: "الموقع الجغرافي",
        Bio: "السيرة الذاتية", 
        Profile_Image: "الصورة الشخصية",
        Cover_Image: " صورة الغلاف",
        Closeـpreview: "تسكير البريفيو",
        Add_Links: ": مواقع التواصل الاجتماعي",
        Add_Extra_Link: "إضافة لينك خارجي",
        Sections: "ميديا",
        Video_section: "إضافة فيديو ",
        Pdf_section: " pdf إضافة",
        search: "بحث",
        Linkss: "اختر الروابط",
        Main_background: "لون الخلفية",
        Button_background: "لون الأزرار",
        Back: "الرجوع",
        Submit: "التأكيد",
        Next: "التالي",
        Link_Tabs: "الضغطات عل اللينكات",
        ChangeـEmail: "تغيير البريد الإلكتروني",
        ChangeـPassword: "تغيير كلمة السر",
        Links: " التواصل الاجتماعي",
        custom_input: "رابط اختياري",
        // Colors: "الالوان",
        Welcome_to_X_tag: "Teb Socail اهلا في",
        totalـcardـTabs: ":اجمالي الزيارات",
        Link_Tabs: "زيارات الروابط",
        New_Email: "البريد الإلكتروني الجديد",
        Confirm_Email: "تاكيد البريد الإلكتروني",
        RESET_EMAIL: "تعديل البريد الإلكتروني",
        dashboard: "لوحة التحكم",
        Email_Verification: "التحقق من البريد الإلكتروني",
        We_have_sent_a_code_to_your_email:
          "تم ارسال كود التحقق الى بريدك الإلكتروني",
        Verify_Account: "التحقق من الحساب",
        Back: "الرجوع",
        Resend_OTP: "اعادة ارسال الكود",
        Home: "الصفحة الرئيسية",
        Logout: "تسجيل الخروج",
        Preview: "التيمبليت",
        phoneNumSecondary: "رقم الهاتف الثاني ",
        save: " حفظ و متابعة",
        title: "العنوان",
        Title:'العنوان',
        start_editing: "بدأ التعديل",
        reenter_password: "تأكيد كلمة السر الجديدة",
        New_password: "كلمة السر الجديدة",
        Email: "البريد الإلكتروني",
        RESET_Password: "تعديل كلمة السر",
        Swipe_Up_To_Preview: "إسحب للأعلى للعرض  ",
        Swipe_Down_To_Preview: "إسحب للأسفل لإيقاف العرض",
        alert: "يرجى عدم اضافة رقم الصفر في البداية",
        successMessage: "Teb Socaile شكرا لإستخدامك   ",
        infosaved: "لقد تم حفظ بياناتك بنجاح",
        returntologin: "العودة للخطوة الاولى",
        Personal:'معلومات الحساب',
        Company:'منشأة طبية',
        BioForCompany:" عن الشركة",
        JobـTitle_company:"عمل الشركة",
        Country:"الدولة",
        City:"المدينة",
        Are_you_using_Teb_as_an_individual_or_a_company:" نوع الحساب ",
        individual:"طبيب",
        Now_lets:".أضف الروابط الخاصة بحساباتك على مواقع التواصل الاجتماعي لمشاركتها بسهولة مع الاخرين",
        Add_your_Media:"أضف وسائطك",
        Now_lets_add:"قم بإضافة فيدوهاتك وملفاتك  إلى ملفك الشخصي للمشاركة بسهولة.",
        Choose_Your_Template_Colors:" الوان الملف الشخصي ",
        clinic_name:' اسم المنشأة الطبية' ,
        Select_Specialties:"التخصص الطبي",
        Save_Changes:"حفظ ",
Manage_Your_Social_Media_Links:"ادارة حسابات التواصل",
Add_your_social_media_links:"قم بإضافة حسابات التواصل الاجتماعي",
Profile_Theme:"شكل الملف الشخصي",
Template_1:"الشكل الاول",
Template_2:"الشكل الثاني",
Our_suggestions:"إقتراحاتنا",
more_Options:"المزيد",

New_Post:"منشور جديد",
Editing_Post:"تعديل المنشور",

Are_you_sure_deleteyou:" ! حذف المنشور؟ لا يمكنك التراجع عن هذه العملية ",
Confirm_Deletion:"تأكيد الحذف",
Confirm:"تأكيد",
Please_enter_your_Info:"المعلومات الأساسية",
Enter_your_contact_details:"معلموامات التواصل الأساسية",
Select_your_country_and_city:"اختر موقعك الجغرافي",
Select_your_medical_specialties:"اختيار التخصص الطبي",

Skip:"تخطي ",
Upload_your_profile_images:"ارفق صورة ملفك الشخصية",
Select_Custom_Colors:"اختر اللون الذي يناسبك",
ButtonـColor:"لون الازاز" ,
Background_Color:"لون الخلفية",
      },
    },
    
  },
  lng: "en", // Default language
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
