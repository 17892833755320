'use client';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Button from './Button';
import styles from './style.module.css';
import Nav from './Nav';

const menu = {
    open: {
        width: "0vw",
        height: "10vh",
        top: "-100px",
        right: "-50px",
        transition: { duration: 0.75, type: "tween", ease: [0.76, 0, 0.24, 1]}
    },
    closed: {
        width: "0",
        height: "40px",
        top: "0px",
        right: "0px",
        transition: { duration: 0.75, delay: 0.35, type: "tween", ease: [0.76, 0, 0.24, 1]}
    }
};

export default function PreViewMain({ language }) {
    const [isActive, setIsActive] = useState(false);
    const [selectedLang, setSelectedLang] = useState(language);

    useEffect(() => {
        setSelectedLang(language);
        console.log(language, 'selectedLangselectedLangselectedLang');
    }, [language]);

    return (
        <div className={`${styles.header} ${selectedLang === "ar" ? "rtl" : "ltr"}`}>
            {/* Button always on top */}
            <Button isActive={isActive} toggleMenu={() => { setIsActive(!isActive) }} />

            {/* Motion div for animated menu */}
            <motion.div 
                className={styles.menu}
                variants={menu}
                animate={isActive ? "open" : "closed"}
                initial="closed"
            >
                <AnimatePresence>
                    {isActive && <Nav />} {/* Nav content displayed inside animated menu */}
                </AnimatePresence>
            </motion.div>
        </div>
    );
}