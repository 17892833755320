import { configureStore } from "@reduxjs/toolkit";
import SidebarSlice from "./Slices/SidebarSlice";
import FormSlice from "./Slices/FormSlice";
import { statsApi } from "./API/statistics";
import { setupListeners } from "@reduxjs/toolkit/query";
import themeReducer from './Slices/themeSlice'; // Your existing form slice

const store = configureStore({
  reducer: {
    form: FormSlice.reducer,
    theme: themeReducer,
    sidebar: SidebarSlice.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(statsApi.middleware),
});

setupListeners(store.dispatch);

export default store;
