import React, { useRef, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCoverImage, setProfileImage } from "../../Redux/Slices/FormSlice";
import { FaRegTrashAlt, FaEdit } from "react-icons/fa"; // Trash and Edit icons
import { useTranslation } from "react-i18next";
import { Cropper, RectangleStencil } from "react-advanced-cropper";
import ProfileCropper from "./Croppers/ProfileCropper"; // Circular Cropper for profile
import "react-advanced-cropper/dist/style.css";

const UploadPhoto = ({ type }) => {
  const dispatch = useDispatch();
  const cropperRef = useRef();
  const { profileImage, coverImage } = useSelector((state) => state.form);
  const { t } = useTranslation();
  const [tempImagePath, setTempImagePath] = useState(null);
  const [openCropper, setOpenCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  const imageRef = useRef();

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageURL = URL.createObjectURL(e.target.files[0]);
      setTempImagePath(imageURL);
      setOpenCropper(true); // Open cropper for image
    }
    e.target.value = null;
  };

  const getCoverCropData = () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          const file = new File([blob], "cropped.jpg", { type: "image/jpeg" });
          getCroppedFile(file);
        }, "image/jpeg");
      }
    }
  };

  const getCroppedFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      if (type === "profile") {
        dispatch(setProfileImage({ image: base64data }));
        setCroppedImage(base64data);
      } else {
        dispatch(setCoverImage({ coverImage: base64data }));
        setCroppedImage(base64data);
      }
      setOpenCropper(false);
      setTempImagePath(null);
    };
  };

  const handleCancel = () => {
    setOpenCropper(false);
  };

  const handleDelete = () => {
    if (type === "cover") {
      dispatch(setCoverImage({ coverImage: null }));
      setCroppedImage(null);
    } else {
      dispatch(setProfileImage({ image: null }));
      setCroppedImage(null);
    }
  };

  const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  return (
    <Box py={"1rem"} textAlign="center">
      <Stack alignItems={"center"} gap={"1rem"}>
      {
        (
          croppedImage ||
          (type === "profile" ? profileImage && profileImage !== "" && !(typeof profileImage === "object" && Object.keys(profileImage).length === 0) : false) ||
          (type === "cover" ? coverImage && coverImage !== "" && !(typeof coverImage === "object" && Object.keys(coverImage).length === 0) : false)
        ) ? (
                    <Box
            sx={{
              position: "relative",
              border: "2px solid black", // Black border
              borderRadius: type === "profile" ? "50%" : "10px",
              width: type === "profile" ? "10.2em" : "20.2em",
              height: type === "profile" ? "10.2em" : "14.43em",
              overflow: "hidden",
            }}
          >
            {/* Dark overlay with buttons, always visible */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay with opacity
                display: "flex", // Always visible
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem",
                zIndex: 1,
              }}
            >
              {/* Edit and Trash Icons */}
              <IconButton onClick={handleDelete} sx={{ color: "red" }}>
                <FaRegTrashAlt />
              </IconButton>

              <IconButton onClick={() => imageRef.current.click()} sx={{ color: "white" }}>
                <FaEdit />
              </IconButton>
            </Box>

            <img
              src={croppedImage || (type === "profile" ? profileImage : coverImage)}
              alt="Cropped"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ) : (
          <Button
            variant="outlined"
            onClick={() => imageRef.current.click()}
            sx={{
              padding: "1rem",
              backgroundColor: "#F8F9FA",
              borderRadius: type === "profile" ? "50%" : "10px",
              width: type === "profile" ? "14.2em" : "20.2em",
              height: type === "profile" ? "14.2em" : "14.43em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px dashed #ccc",
              textAlign: "center",
            }}
          >
            + {type === "profile" ? t("Profile_Image") : t("Cover_Image")}
          </Button>
        )}
      </Stack>

      <input
        onChange={handleImageChange}
        type="file"
        accept="image/*"
        ref={imageRef}
        hidden
      />

      {/* Conditionally render the cropper for profile (circle) and cover (rectangle) */}
      {openCropper && type === "profile" && (
        <ProfileCropper
          src={tempImagePath}
          getCroppedFile={getCroppedFile}
          onCancel={handleCancel}
        />
      )}

      {openCropper && type === "cover" && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent white for glass effect
            backdropFilter: "blur(12px)", // Frosted glass effect
            zIndex: 10000,
            padding: { xs: "2rem", md: "4rem" },
            animation: "fadeIn 0.5s ease", // Smooth fade-in animation
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.7)", // Subtle shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.2)", // Light border to complete glass effect
          }}
        >
          {/* Cropper with smoother borders and box shadow */}
          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.9)", // Slightly opaque background inside
              borderRadius: "10px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)", // Modern shadow effect
              padding: "1rem",
              backdropFilter: "blur(5px)",
              width: "100%",
              maxWidth: "450px", // Larger for better usability
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Cropper
              ref={cropperRef}
              src={tempImagePath}
              stencilComponent={RectangleStencil}
              className="advanced-cropper"
              stencilProps={{
                aspectRatio: 1.4,
              }}
              style={{
                maxHeight: "40vh",
                width: "100%",
                borderRadius: "10px", // Rounded corners for cropper container
              }}
              viewMode={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              guides={true}
              cropBoxResizable={true}
              dragMode="move"
            />
          </Box>
      
          {/* Buttons with smooth transitions, casual touches, and hover effects */}
          <Stack direction="row" spacing={3} mt={3}>
            <Button
              sx={{
                width: "fit-content",
                padding: "0.8rem 2rem",
                fontSize: "1rem",
                fontWeight: 600,
                backgroundColor: "#4CAF50",
                color: "#fff",
                borderRadius: "8px", // Rounded buttons for casual feel
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#45A049",
                },
              }}
              variant="contained"
              onClick={getCoverCropData}
            >
              {t("Confirm")}
            </Button>
            <Button
              sx={{
                width: "fit-content",
                padding: "0.8rem 2rem",
                fontSize: "1rem",
                fontWeight: 600,
                backgroundColor: "#F44336",
                color: "#fff",
                borderRadius: "8px",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#E53935",
                },
              }}
              variant="contained"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default UploadPhoto;