import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, Box, Typography, TextField, Button, Autocomplete, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import UploadPhoto from "../../Components/ImageHanding/UploadPhoto";
import { useLanguage } from "../../hook/useLocalStorage";
import { debounce } from "lodash";
import CustomPhoneInput from "../../Components/CustomPhoneInput/CustomPhoneInput";
import {
  setBasicInfo,
  setCustomContacts,
  setFirstName,
  setIsPersonal,
  setLastName,
  setLocation,
  removeCustomContacts
} from "../../Redux/Slices/FormSlice";
import medicalCategories from "./medicalCategories.json";

const FirstSection = ({ handleSubmit, isLoading }) => {
  const { t } = useTranslation();
  const tex = useLanguage();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  // const [activeOption, setactiveOption] = useState(null);

  const [selectedMedicalSpecialties, setSelectedMedicalSpecialties] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberWA, setPhoneNumberWA] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const lang=localStorage.getItem('lang')
  const [activeOption, setActiveOption] = useState(form?.isPersonal == "true" ? 1 : 2);

  const categories = medicalCategories[lang];
  const Medicaloptions = Object.entries(categories).flatMap(([category, specialties]) =>
    Array.isArray(specialties) ? specialties.map(specialty => `${category} — ${specialty}`) : []
  );

  const languageKey = lang == 'en' ? 'name_en' : 'name_ar';
  const isArabic = lang == "ar";
  useEffect(() => {
    axios.get("https://tebsocial.api.cubex-dev.com/api/countries")
      .then(response => setCountries(response.data))
      .catch(error => console.error("Error fetching countries:", error));
  }, []);

  const fetchCities = async (country, selectedCityName) => {
  await  axios.get(`https://tebsocial.api.cubex-dev.com/api/countries/${country.id}/cities`)
      .then(response => {
        console.log(country,'response')

        setCities(response.data);
        const cityMatch = response.data.find(city => city[languageKey] == selectedCityName);

        if (cityMatch) { 
          setSelectedCity(cityMatch);
        } else {
          setSelectedCity(null);
        }
      })
      .catch(error => console.error("Error fetching cities:", error));
  };

  useEffect(() => {
    if (form?.basic?.location) {
      const [previousCountry, previousCity] = form.basic.location.split(', ');

      const countryMatch = countries.find(country => country[languageKey] == previousCountry);
      if (countryMatch) {
        setSelectedCountry(countryMatch);
        fetchCities(countryMatch, previousCity);
      }
    }
  }, [form.basic.location, countries, languageKey]);



  useEffect(() => {
  
    setActiveOption(form?.isPersonal == "true" ? 1 : 2)

    const specialties = form?.contactsCustom?.map((contact) => {
      const contactID=contact.id
      const [category, specialty] = contact?.link.split(" — ");
      return {
        contactID,
        category,
        specialty,
      };
    });
    specialties?.forEach(({contactID, category, specialty }, index) => {
      dispatch(
        setCustomContacts({
          id: contactID,  // Use the original ID from contactsCustom
          link: `${category} — ${specialty}`,

          name_link: `${category} — ${specialty}`,
        })
      );
      setSelectedMedicalSpecialties(
        specialties?.map((s) => `${s.category} — ${s.specialty}`)
      );
    });
  }, [form.basic.location, countries]);

  useEffect(() => {
    // When form updates, reset variables
   

    setPhoneNumber(form?.basic?.phoneNumber || '');
    setPhoneNumberWA(form?.basic?.phoneNumberWA || '');
    setJobTitle(form?.basic?.jobTitle || '');
   
  }, [form]);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    dispatch(setBasicInfo({ basic: { ...form.basic, phoneNumber: value } }));
  };

  const handlePhoneNumberWAChange = (value) => {
    setPhoneNumberWA(value);
    dispatch(setBasicInfo({ basic: { ...form.basic, phoneNumberWA: value } }));
  };

  const handleJobTitleChange = (event) => {
    setJobTitle(event.target.value);
    dispatch(setBasicInfo({ basic: { ...form.basic, jobTitle: event.target.value } }));
  };
  const handleOptionClick = (option) => {
    setActiveOption(option);
    dispatch(setIsPersonal({ isPersonal: option === 1 ? "true" : "false" }));
  };


  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
    setSelectedCity(null);
    if (newValue) {
      axios.get(`https://tebsocial.api.cubex-dev.com/api/countries/${newValue.id}/cities`)
        .then(({ data }) => setCities(data))
        .catch(err => console.error(err));
    }
  };

  const handleCityChange = (event, newValue) => {
    setSelectedCity(newValue);
    if (newValue && selectedCountry) {
      dispatch(setLocation({ location: `${selectedCountry[languageKey]}, ${newValue[languageKey]}` }));
    }
  };
  const handleSpecialtyChange = (event, newValue) => {
    if (activeOption === 1) {
      // For individual, allow only a single specialty
      const specialty = newValue ? newValue[0] : null;
      setSelectedMedicalSpecialties(specialty ? [specialty] : []);
      if (specialty) {
        dispatch(setCustomContacts({ id: 0, link: specialty, name_link: specialty }));
      } else {
        dispatch(removeCustomContacts(0));  // Assuming 'removeCustomContacts' takes an ID and removes the contact
      }
    } else {
      // For company, allow multiple specialties
      setSelectedMedicalSpecialties(newValue);
      // Dispatch all new specialties to the store
      newValue.forEach((specialty, index) => {
        dispatch(setCustomContacts({ id: index, link: specialty, name_link: specialty }));
      });
      // Remove deleted specialties from Redux store
      // Find which specialties are removed by comparing current state
      const removedSpecialties = selectedMedicalSpecialties.filter(spec => !newValue.includes(spec));
      removedSpecialties.forEach(spec => {
        const indexToRemove = selectedMedicalSpecialties.findIndex(s => s === spec);
        dispatch(removeCustomContacts(indexToRemove));
      });
    }
  };
  
  const handleFormSubmit = (event) => {
    event.preventDefault(); 
    handleSubmit();
  };

  return (
    <Box sx={{ width: '100%', padding: '2rem', mt: 5, backgroundColor: '#f9f9f9', borderRadius: '12px' }}>
    {isLoading && (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    )}
  
    <Typography 
    variant="h6" // Change from h5 to h6 to reduce the size
    sx={{ 
      mb: 2, // Adjust margin
      color: "#353D4B", 
      fontWeight: 'bold', 
      textAlign: 'start' // Align the title to the start (left)
    }}
  >
    {t("Are_you_using_Teb_as_an_individual_or_a_company")}
  </Typography>
  
  <Box sx={{ display: 'flex', justifyContent: 'start', mb: 2 }}>
    <Button
      onClick={() => handleOptionClick(1)}
      variant="contained"
      sx={{
        py: 1, // Reduce padding for smaller buttons
        px: activeOption === 1 ? 4 : 2, // Reduce horizontal padding
        borderRadius: '20px', // Make border-radius smaller to match inputs
        marginX: "10px",
        backgroundColor: activeOption === 1 ? "#17488a" : "#fff",
        color: activeOption === 1 ? "#fff" : "#17488a",
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '0.875rem', // Reduce font size to match input label size
        boxShadow: activeOption === 1 ? '0px 4px 12px rgba(23, 72, 138, 0.3)' : 'none',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
    >
      {t("individual")}
    </Button>
    <Button
      onClick={() => handleOptionClick(2)}
      variant="contained"
      sx={{
        py: 1, // Reduce padding for smaller buttons
        px: activeOption === 2 ? 4 : 2, // Reduce horizontal padding
        borderRadius: '20px', // Match input field styling
        marginX: "10px",
        backgroundColor: activeOption === 2 ? "#17488a" : "#fff",
        color: activeOption === 2 ? "#fff" : "#17488a",
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '0.875rem', // Match input label font size
        boxShadow: activeOption === 2 ? '0px 4px 12px rgba(23, 72, 138, 0.3)' : 'none',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
    >
      {t("Company")}
    </Button>
  </Box>
  
    <form onSubmit={handleFormSubmit}>
      <Grid container spacing={4}>
        {/* Personal Info */}
        {form.isPersonal === "true" && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                variant="standard"
                fullWidth
                label={t("First_Name")}
                value={form?.basic?.firstName || ''}
                onChange={e => dispatch(setBasicInfo({ basic: { ...form.basic, firstName: e.target.value } }))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="standard"
                fullWidth
                label={t("Last_Name")}
                value={form?.basic?.lastName || ''}
                onChange={e => dispatch(setBasicInfo({ basic: { ...form.basic, lastName: e.target.value } }))}
              />
            </Grid>
          </>
        )}
  
        {/* Clinic/Business Name */}
        <Grid item xs={12} md={6}>
          <TextField
            variant="standard"
            fullWidth
            label={t("clinic_name")}
            value={form?.basic?.businessName || ''}
            onChange={e => dispatch(setBasicInfo({ basic: { ...form.basic, businessName: e.target.value } }))}
          />
        </Grid>
  
        {/* Reservation Link */}
        <Grid item xs={12} md={6}>
          <TextField
            variant="standard"
            fullWidth
            label={t("ReservationLink")}
            value={jobTitle}
            onChange={handleJobTitleChange}
          />
        </Grid>
  
        {/* Contact Info */}
        <Grid item xs={12} md={6}>
          <CustomPhoneInput
            istextblack={true}
            label={t("PhoneـNumber")}
            value={form.basic.phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </Grid>
  
        <Grid item xs={12} md={6}>
          <CustomPhoneInput
            istextblack={true}
            label={t("WhatsAppـPhoneـNumber")}
            value={form.basic.phoneNumberWA}
            onChange={handlePhoneNumberWAChange}
          />
        </Grid>
  
        <Grid item xs={12} md={6}>
          <TextField
            variant="standard"
            fullWidth
            label={t("Email")}
            value={form?.basic?.email || ''}
            onChange={e => dispatch(setBasicInfo({ basic: { ...form.basic, email: e.target.value } }))}
          />
        </Grid>
  
        {/* Country and City */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={countries}
            getOptionLabel={option => option[languageKey]}
            value={selectedCountry}
            onChange={handleCountryChange}
            renderInput={params => <TextField {...params} label={t("Country")} variant="standard" />}
          />
        </Grid>
  
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={cities}
            disabled={!selectedCountry}
            getOptionLabel={option => option[languageKey]}
            value={selectedCity}
            onChange={handleCityChange}
            renderInput={params => <TextField {...params} label={t("City")} variant="standard" />}
          />
        </Grid>
  
        {/* Medical Specialties */}
        <Grid item xs={12}>
          <Autocomplete
            multiple={activeOption === 2}
            options={Medicaloptions}
            groupBy={(option) => option.split(' — ')[0]} 
            getOptionLabel={(option) => option.split(' — ')[1]}
            value={activeOption === 2 ? selectedMedicalSpecialties : (selectedMedicalSpecialties[0] || null)}
            onChange={(event, newValue) => handleSpecialtyChange(event, activeOption === 2 ? newValue : [newValue])}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={t("Select_Specialties")}
                InputLabelProps={{
                  sx: {
                    color: '#888',
                    fontSize: '1rem',
                    "&.Mui-focused": {
                      color: '#17488a',
                    },
                  },
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={option}
                  sx={{
                    '.MuiChip-deleteIcon': {
                      margin: '0',
                    },
                  }}
                />
              ))
            }
          />
        </Grid>
  
        {/* Bio */}
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            label={t("Bio")}
            multiline
            rows={4}
            value={form?.basic?.bio || ''}
            onChange={e => dispatch(setBasicInfo({ basic: { ...form.basic, bio: e.target.value } }))}
          />
        </Grid>
  
        {/* Photo Uploads */}
       
        <Grid item xs={12}>
          <UploadPhoto type="cover" />
        </Grid>
  
        {/* Submit Button */}
        <Grid item xs={12} textAlign="center">
          <Button type="submit" variant="contained" disabled={isLoading} sx={{ py: 1.5, px: 5, borderRadius: '30px' }}>
            {isLoading ? <CircularProgress size={24} /> : t("Save_Changes")}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Box>
  );
};

export default FirstSection;
