import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { FaArrowLeft } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';
import tinycolor from "tinycolor2";
import { useSelector } from 'react-redux';
import { IoVideocam } from 'react-icons/io5'; // Example using react-icons

const PostGallery = () => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const form = useSelector(state => state.form);

  const mainBackgroundColor = form.colors.mainBackground;
  const buttonsBackground = form.colors.buttonsBackground;
  const textcolor = tinycolor(mainBackgroundColor).isDark() ? "#ffffff" : "#000000";
  const textbottoncolor = tinycolor(buttonsBackground).isDark() ? "#ffffff" : "#000000";





 
 
  const modalContentRef = useRef(null);

  const handleOpenPost = (index) => {
    setOpen(true);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setSelectedIndex(null);
    setOpen(false);
  };

  useEffect(() => {
    if (open && selectedIndex !== null && modalContentRef.current) {
      setTimeout(() => {
        const selectedElement = modalContentRef.current.children[selectedIndex];
        if (selectedElement) {
          selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 300);
    }
  }, [open, selectedIndex]);

  const isVideo = (url) => {
    return url?.match(/\.(mp4|webm|ogg)$/i);
  };

  const handleVideoLoad = (videoRef) => {
    if (videoRef) {
      videoRef.currentTime = 0.2; 
      videoRef.pause(); 
    }
  };

  // Calculate number of posts and empty slots in the last row
  const [totalPosts,settotalPosts]=useState(0) 
  const [remainder,setremainder]=useState(0) 

useEffect(()=>{
  settotalPosts(form?.sections?.length)
  setremainder(form?.sections?.length % 3)
  console.log('remainder',totalPosts)

},[form?.sections])
  return (
    <>
      {/* Grid View */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)', 
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          gap: 0.6,
          backgroundColor: form.colors.buttonsBackground,
        }}
      >
      {form?.sections?.map((item, index) => (
        <motion.div
          key={index}
          onClick={() => handleOpenPost(index)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ }}
          style={{
            cursor: 'pointer',
            position: 'relative',
            width: '100%',
            paddingBottom: '100%',
            overflow: 'hidden',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: form.colors.buttonsBackground,
          }}
        >
          {isVideo(item.media) ? (
            <>
              <video
                src={item.media}
                preload="auto"
                muted
                controls={false}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  objectFit: 'cover',
                  backgroundColor: form.colors.buttonsBackground,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                onLoadedMetadata={(e) => handleVideoLoad(e.target)}
              />
      
              {/* Black overlay */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: form.colors.buttonsBackground,
                  opacity: 0.2,
                  zIndex: 2,
                }}
              />
      
              {/* White video icon in the top-right corner */}
              <IoVideocam
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  color: textbottoncolor,
                  fontSize: '24px',
                  zIndex: 3,
                }}
              />
            </>
          ) : (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${item.media})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: form.colors.buttonsBackground,
              }}
            />
          )}
        </motion.div>
      ))}

        {/* Add dummy grid items to fill empty slots if there are fewer than 3 items in the last row */}
        {remainder !== 0 &&
          Array.from({ length: 3 - remainder }).map((_, idx) => (
            <Box
              key={`empty-${idx}`}
              sx={{
                width: '102%',
                paddingBottom: '100%', // Maintain square aspect ratio
                backgroundColor: form.colors.mainBackground, // Use bgColor instead of buttonColor
              }}
            />
          ))}
      </Box>

      {/* List View */}
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '1oo%',
              height: '100%',
              backgroundColor: form.colors.mainBackground || '#fff',
              zIndex: 1000,
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1001,
                backgroundColor: form.colors.mainBackground || '#fff',
                display: 'flex',
                alignItems: 'center',
                padding: '10px 20px',
                boxShadow: 'none',
              }}
            >
              <IconButton onClick={handleClose}>
                <FaArrowLeft style={{ color: textcolor || '#000' }} />
              </IconButton>
              <Typography variant="h6" sx={{ marginX: '20px', color: textcolor || '#000' }}>
                {form?.SelectedLanguage === 'ar' ? 'قائمة المنشورات' : 'Post List'}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 2,
                padding: '10px',
              }}
              ref={modalContentRef}
            >
              {form?.sections?.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ delay: index * 0.1 }}
                  style={{
                    backgroundColor: form.colors.mainBackground || '#fff',
                  }}
                >
                  {isVideo(item.media) ? (
                    <video
                      src={item.media}
                      controls
                      style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                  ) : (
                    <motion.img
                      src={item.media}
                      alt={item.title}
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  )}

                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                      textAlign: form?.SelectedLanguage === 'ar' ? 'right' : 'left',
                      fontWeight: 'bold',
                      padding: '16px',
                      marginTop: "-10px",
                      marginBottom: "10px",
                      color: textcolor,
                    }}
                  >
                    {item.title}
                  </Typography>
                </motion.div>
              ))}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PostGallery;