import "./App.css";
import ThemeRoutes from "./Routes/Routes";
import { Box, ThemeProvider, createTheme, Typography, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useEffect, useState } from "react";
import { useLanguage } from "./hook/useLocalStorage";
import { useDispatch } from "react-redux";
import { setSelectedLanguage } from "./Redux/Slices/FormSlice";
import axios from "axios";
import { MdDashboard, MdPostAdd, MdPerson, MdLink, MdPalette } from "react-icons/md";
import { motion } from "framer-motion";
import { MdOutlineDashboard } from "react-icons/md";
import { FiBarChart2 } from "react-icons/fi";
import { BsFillInfoCircleFill, BsFillPeopleFill } from "react-icons/bs";
import { FaPalette } from "react-icons/fa";

import Navigation from "./Components/Navigation/Navigation";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();

  const [languagee, setLanguagee] = useState(lang);
  const { language, setLanguage } = useLanguage();
  const fontFamily = lang === "ar" ? "system-ui" : "Roboto_bold";
  const profile = localStorage.getItem("profile"); 
  const token = localStorage.getItem("token");

  const [activeStep, setActiveStep] = useState(location.state?.activeSection || 0);

  useEffect(() => {
    if (location.state?.activeSection !== undefined) {
      setActiveStep(location.state.activeSection);
    }
  }, [location.state]);

  const routeHeaders = {
    "/dashboard": {
      title: languagee=='en'?"Dashboard":"لوحة التحكم",
      icon: <FiBarChart2 size={24} />,
      description: languagee=='en'?"Overview of your analytics and key metrics.":"نظرة عامة على تحليلات والمؤشرات الرئيسية",
    },
    "/posts": {
      title: languagee=='en'?"Posts":"المنشورات",
      
      icon: <MdOutlineDashboard size={24} />,
      description: languagee=='en'?"Create, edit, and manage your posts from here.":"أنشئ، حرر، وأدِر منشوراتك من هنا",
    },
  
  
    
    "/steper": {
      titles: [languagee=='en'?"Basic Information":"المعلومات الأساسية", languagee=='en'?"Social Links":"روابط التواصل الاجتماعي",languagee=='en'? "Theme & Colors":"شكل الملف والألوان"],
      icons: [<BsFillInfoCircleFill size={24} />, <BsFillPeopleFill size={24} />, <FaPalette size={24} />],
      descriptions: [
        languagee=='en'?"Add your basic personal information to complete your profile.":"    أضف معلوماتك الشخصية الأساسية لإكمال ملفك الشخصي",
         languagee=='en'?"Add links to your social profiles for better visibility.":"أضف روابط منصات التواصل الاجتماعي  إلى ملفك الشخصي لتعزيز ظهورك",
         languagee=='en'? "Customize your profile with themes and colors.":"خصص ملفك الشخصي باستخدام الثيمات والألوان",
      ],
    },
  };

  const renderHeaderSection = () => {
    const currentRoute = routeHeaders[location.pathname];
    if (!currentRoute) return null;

    const title = location.pathname === "/steper"
      ? currentRoute.titles[activeStep] || currentRoute.titles[0]
      : currentRoute.title;

    const description = location.pathname === "/steper"
      ? currentRoute.descriptions[activeStep] || currentRoute.descriptions[0]
      : currentRoute.description;

    const icon = location.pathname === "/steper"
      ? currentRoute.icons[activeStep] || currentRoute.icons[0]
      : currentRoute.icon;

    return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        style={{
          background: "rgba(112, 94, 168, 0.7)",
          backdropFilter: "blur(10px)",
          padding: "1.5rem",
          borderRadius: "12px",
          margin: "1rem",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Box sx={{ marginRight: "1rem",marginLeft: "1rem" }}>{icon}</Box>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
              {title}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.8, color: "#fff" }}>
              {description}
            </Typography>
          </Box>
        </Stack>
      </motion.div>
    );
  };

  useEffect(() => {
    i18n.changeLanguage(languagee);
  }, [languagee]);

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#17488a",
      },
      secondary: {
        main: "#705ea8",
      },
      lighter: {
        main: "#497cbe",
      },
    },
    typography: {
      fontFamily: `${fontFamily}`, 
      title: {
        color: "#fff",
        fontSize: "0.9rem",
        display: "block",
      },
      linkSelected: {
        color: "#fff",
        backgroundColor: "#fff",
        fontSize: "0.9rem",
        display: "block",
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            style: {
              textAlign: lang === "ar" ? "right" : "left",
              right: lang === "ar" ? 0 : "auto",
              left: lang === "ar" ? "auto" : 0,
              direction: lang === "ar" ? "rtl" : "ltr",
              fontSize: "1rem",
              color: "#000",
            },
          },
          InputProps: {
            inputProps: {
              style: {
                textAlign: lang === "ar" ? "right" : "left",
                direction: lang === "ar" ? "rtl" : "ltr",
                color: "#000",
              },
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang === "en" || lang === "ar") {
      setLanguagee(lang);
      setLanguage(lang);
    } else {
      localStorage.setItem("lang", "en");
      setLanguagee("en");
      setLanguage("en");
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(languagee);
    setLanguagee(languagee);
    setLanguage(languagee);
    dispatch(setSelectedLanguage({ SelectedLanguage: languagee }));
  }, [languagee]);

  const handleLanguageToggle = async () => {
    const newLang = languagee === "en" ? "ar" : "en";
    const formData = new FormData();
    formData.append("SelectedLanguage", newLang);

    try {
      await axios.post(
        `https://tebsocial.api.cubex-dev.com/api/profile/${profile}`, 
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLanguagee(newLang);
      setLanguage(newLang);

      localStorage.setItem("lang", newLang);
      i18n.changeLanguage(newLang);
    } catch (error) {
      console.error("Error updating language...");
    }
  };

  const handleLogout = () => {
    navigate("/");
  };

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Navigation
          languagee={languagee}
          setLanguagee={setLanguagee}
          handleLanguageToggle={handleLanguageToggle}
          handleLogout={handleLogout}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            zIndex: 1100,
            direction: languagee === "ar" ? "rtl" : "ltr",
            marginTop: location.pathname !== "/" ? "100px" : 0,
          }}
        >
       {renderHeaderSection()}
      
          <ThemeRoutes />
        </Box>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;